#quick-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 40px;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.5);
    opacity: 0;
    visibility: hidden;
  }

  .close-btn {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .search-info {
    margin-top: 21px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    color: $color-black;
    line-height: 1.25;
  }

  h6 {
    margin-top: 20px;
    margin-bottom: 16px;
    color: $color-black;

    a {
      color: $color-black;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  .btn-wrp {
    margin-top: 24px;
    margin-bottom: 4px;
    text-align: center;
  }

  .links-list {
    margin-top: 16px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      a {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $color-black;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  .brands {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
      margin-top: -1px;
      margin-bottom: 0;
      border: 1px solid $color-grey4;

      &:nth-child(even) {
        border-left-color: transparent;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px;
        text-align: center;

        img {
          max-width: 120px;
          max-height: 40px;
        }
      }
    }
  }

  .product-list {
    margin-left: -20px;
    margin-right: -20px;

    .product-element {
      margin-top: -1px;
      border-left-color: transparent;

      &:first-child {
        border-left-color: $color-grey4;
      }
    }
  }

  .quick-search-form {
    .form-item {
      position: relative;

      .form-control {
        padding-left: 28px;
        padding-right: 28px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;

        &::placeholder {
          font-weight: 500;
          color: $color-grey12;
        }
      }

      .icon-search {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 17px;
        color: $color-grey12;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 64px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    overflow: visible;

    &.active {
      &.has-content {
        height: calc(100vh - 64px);

        #search-suggestions-wrp {
          max-height: 70vh;
          overflow: auto;
        }

        .overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .quick-search-inner {
      position: relative;
      z-index: 2;
      padding-top: 10px;
      padding-bottom: 38px;
      border-bottom: 1px solid $color-grey4;
      background: $color-white;
    }

    .search-info {
      margin-top: 0;
    }

    .quick-search-form {
      margin-bottom: 20px;
    }

    h6 {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .product-list {
      margin-left: 0;
      margin-right: 0;
    }

    .brands {
      li {
        width: 25%;
      }
    }
  }
}