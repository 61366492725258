.range-slider-ui {
  margin-top: 38px;
  margin-bottom: 38px;
  background-color: $color-grey;

  .noUi-tooltip {
    border-radius: 0;
    border: 0;
    background-color: $color-black;
    color: $color-white;
  }

  .noUi-value {
    color: $color-dark-grey;
  }

  .noUi-marker-horizontal {
    &.noUi-marker {
      background-color: $color-grey;
    }
  }

  .noUi-connect {
    background-color: $color-red;
  }
}