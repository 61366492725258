.editor_type_text {
  &.editor_type_title_block {
    font-weight: 300;

    h1, .h1 {
      margin-bottom: 14px;
    }
  }

  @include media-breakpoint-down(md) {
    h2, .h2, h3, .h3 {
      text-align: left;
    }
  }
  @include media-breakpoint-up(md) {
    &.editor_type_title_block {
      h1, .h1 {
        margin-bottom: 19px;
      }
    }
  }
}

.editor_type_banner {
  margin-bottom: 61px;
  padding: 0px;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .banner-inner-wrp {
    display: flex;
    align-items: center;
    padding: 28px;
    min-height: 222px;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;

    .banner-content-wrp {
      max-width: 70%;

      h2 {
        margin: 0px;
        margin-bottom: 10px;
        line-height: 30px;
        text-transform: initial;
        letter-spacing: 0.326068px;
      }

      p {
        margin-bottom: 12px;
      }

      button {
        margin-bottom: 15px;
        padding: 7px 0px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.13em;
        color: #000000;
        background-color: transparent;
        border: none;
        box-shadow: inset 0 0.15em transparent, inset 0 -0.15em #000000;
      }

      &.fonts-1 {
        h2 {
          margin-bottom: 5px;
          font-size: 28px;
          line-height: 30px;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }

    &.ha-left {
      justify-content: flex-start;
    }

    &.ha-center {
      justify-content: center;
    }

    &.ha-right {
      justify-content: flex-end;
    }
  }

  &.ha-left {
    .banner-inner-wrp {
      background-position: left center;
    }
  }

  &.ha-center {
    .banner-inner-wrp {
      background-position: center;
    }
  }

  &.ha-right {
    .banner-inner-wrp {
      background-position: right center;
    }
  }

  &.full_width {
    padding: 0px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 55px;

    .banner-inner-wrp {
      padding: 70px;
      min-height: 540px;

      .banner-content-wrp {
        h2 {
          margin-bottom: 18px;
          font-size: 70px;
          line-height: 74px;
          letter-spacing: 0.793605px;
        }

        p {
          margin-bottom: 20px;
          font-weight: 300;
          font-size: 17px;
          line-height: 28px;
        }

        button {
          margin-bottom: 0px;
          padding: 4px 0px;
          font-size: 13px;
          line-height: 22px;
        }

        &.fonts-1 {
          h2 {
            margin-bottom: 19px;
            font-size: 50px;
            line-height: 48px;
            letter-spacing: 0px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.editor_type_features {
  overflow: hidden;
  margin-top: -39px;
  margin-bottom: 25px;
  padding: 40px 15px;

  .inner-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 28px 28px 30px 28px;
      min-height: 260px;
      width: calc(100% - 20px);
      max-width: 350px;
      text-align: center;
      background-color: #F9F9FB;
      transition: all .3s;

      .img-wrp {
        margin-bottom: 10px;

        img {
          margin: 0px auto;
          height: 40px;
          width: auto;
        }
      }

      h3 {
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
      }

      p {
        margin-bottom: 0px;
        font-size: 14.189px;
        line-height: 18px;
      }

      &.active {
        z-index: 1;
        max-width: 370px;
        min-height: 300px;
        width: 100%;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 40px 0px;

    .inner-wrp {
      flex-direction: row;

      .item {
        min-height: 390px;
        max-width: initial;
        width: calc(100% / 3);

        .img-wrp {
          margin-bottom: 21px;

          img {
            height: 68px;
          }
        }

        h3 {
          margin-bottom: 3px;
          font-size: 22px;
          line-height: 26px;
        }

        p {
          font-size: 17px;
          line-height: 22px;
        }

        &.active {
          max-width: initial;
          min-height: 416px;
          width: calc(100% / 3);
        }
      }
    }
  }
}

.editor_type_texts_banner {
  .inner-wrp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;

    .banner-wrp {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      position: relative;
      overflow: hidden;
      margin: -40px 27px 0px 27px;
      min-height: 290px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: initial;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text-wrp {
      &.text-top-wrp {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 45px 28px 75px 28px;
        background-color: #F9F9FB;

        h1 {
          margin-top: 0px;
          margin-bottom: 9px;
        }

        p {
          line-height: 20px;
        }
      }

      &.text-bottom-wrp {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        padding: 54px 28px 32px 28px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;

      .banner-wrp {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        margin: 0px;

        img {
          width: auto;
        }
      }

      .text-wrp {
        &.text-top-wrp {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          padding: 96px 69px 79px 69px;

          .inner-text-wrp {
            max-width: 380px;
          }

          h1 {
            margin-bottom: 19px;
          }

          p {
            line-height: 26px;
          }
        }

        &.text-bottom-wrp {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          padding: 101px 69px 75px 69px;

          .inner-text-wrp {
            max-width: 500px;
          }
        }
      }
    }
  }
}

.content-wrapper {
  background: #fff;
  border: 1px solid #f2f2f2;
  padding: 30px 30px 15px 30px;
  margin: 20px 0;
}

/*BOTTOM NEW*/

.page_editor_template {
  margin-bottom: 80px;

  &.full_width {
    max-width: 100%;
  }
}

.editor_type_banners_slider {
  position: relative;

  .slider-slider {
    .slider-item {
      position: relative;
      line-height: 0;

      img {
        width: 100%;
        height: 640px;
        object-fit: cover;
      }
    }

    .content-wrp {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 36%;

      h2 {
        margin-bottom: 24px;
        color: $color-white;
      }
    }
  }

  .slider-nav {
    left: 0;
    bottom: 41%;
    margin-left: -6px;
  }

  @include media-breakpoint-up(sm) {
    .slider-slider {
      .content-wrp {
        .content {
          max-width: 60%;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .slider-slider {
      .slider-item {
        img {
          height: 480px;
        }
      }

      .content-wrp {
        top: 35%;
        bottom: unset;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;

        .container {
          display: flex;
        }
      }
    }

    .slider-nav {
      bottom: unset;
      top: 25%;
    }
  }
  @include media-breakpoint-up(lg) {
    .slider-slider {
      .content-wrp {
        .content {
          max-width: 50%;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .slider-slider {
      .slider-item {
        img {
          height: 640px;
        }
      }

      .content-wrp {
        h2 {
          margin-bottom: 33px;
        }

        .content {
          max-width: 60%;
        }
      }
    }

    .slider-nav {
      top: 26%;
    }
  }
}

.editor_type_our_benefits {
  margin-bottom: 0;

  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.33;
      color: $color-black;
      text-align: center;

      i {
        font-size: 26px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .list {
      column-gap: 50px;
      padding-top: 33px;
      padding-bottom: 33px;

      span {
        flex-direction: row;
        font-weight: 500;
        font-size: 14px;

        i {
          margin-right: 10px;
        }
      }
    }
  }
}

.editor_type_banner_4 {
  .list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;

    .item {
      position: relative;
      width: 212px;
      flex-shrink: 0;
      line-height: 0;

      img {
        aspect-ratio: 0.66/1;
        object-fit: cover;
      }

      .content {
        position: absolute;
        bottom: 24px;
        left: 0;
        z-index: 1;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }

      h3 {
        margin-bottom: 5px;
        color: $color-white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .list {
      overflow: visible;

      .item {
        width: 25%;

        img {
          width: 100%;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .list {
      .item {
        .content {
          bottom: 48px;
          padding-left: 15px;
          padding-right: 15px;

          h3 {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.editor_type_collection {
  .product-list {
    flex-wrap: nowrap;
    overflow: auto;

    .product-element {
      border-left-color: transparent;

      &:first-child {
        border-left-color: $color-grey4;
      }
    }
  }
}

.editor_type_customer_reviews {
  h2 {
    margin-bottom: 17px;
  }

  .slider-wrp {
    position: relative;
  }

  .tns-outer {
    background: $color-grey5;
  }

  .slider-nav {
    bottom: 30px;
    left: 14px;
    width: auto;

    .slider-arrow-btn {
      color: $color-black;
    }

    .slider-nav-counter {
      span {
        color: $color-black;
      }
    }
  }

  .item {
    .img {
      line-height: 0;

      img {
        width: 100%;
        height: 254px;
        object-fit: cover;
      }
    }

    .content {
      padding: 28px 20px 82px;
    }

    .rating {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      i {
        font-size: 15px;
        line-height: 1;
        color: $color-black;
      }
    }

    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: $color-black;
    }

    h5 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.25;
      color: $color-black;
    }
  }

  @include media-breakpoint-up(md) {
    h2 {
      margin-bottom: 22px;
    }

    .slider-nav {
      bottom: 40px;
      left: 46px;
    }

    .item {
      display: inline-grid !important;
      grid-template-columns: repeat(2, 1fr);

      .img {
        order: 2;

        img {
          height: 464px;
        }
      }

      .content {
        order: 1;
        padding: 32px 64px 90px 32px;

        p {
          margin-bottom: 25px;
          font-size: 18px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .slider-nav {
      left: 78px;
    }

    .item {
      .content {
        padding: 68px 124px 90px 64px;

        p {
          font-size: 20px;
        }
      }
    }
  }
}

.editor_type_banner_banner {
  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .banner-wrp {
      img {
        width: 100%;
        height: 280px;
        object-fit: cover;
      }

      .banner-inner-wrp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 28px;
        min-height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h3 {
          margin-bottom: 5px;
          color: $color-white;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .banner-wrp {
        img {
          height: 560px;
        }

        .banner-inner-wrp {
          min-height: 560px;

          h3 {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.editor_type_latest_news {
  .news-list {
    display: flex;
    column-gap: 10px;
    margin-right: -20px;
    overflow: auto;
  }

  .news-element {
    width: 206px;
  }

  @include media-breakpoint-up(md) {
    .news-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 27px;
      margin-right: 0;
      overflow: visible;
    }

    .news-element {
      width: 100%;
    }
  }
}

.editor_type_banner_text {
  margin-bottom: 56px;

  &.type-50-black {
    background: $color-dark;

    .content {
      padding-top: 40px;
      padding-bottom: 40px;

      h2 {
        color: $color-white;
      }

      .html-content {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: $color-white;
      }
    }
  }

  .inner-wrp {
    flex-direction: column;
  }

  .img {
    margin-left: -20px;
    margin-right: -20px;

    img {
      width: 100%;
      aspect-ratio: 1.58/1;
      object-fit: cover;
    }
  }

  .content {
    padding: 24px 0;

    &.text-center {
      .button {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.text-end {
      .button {
        margin-left: auto;
      }
    }
  }

  .title {
    width: 100%;
    margin-bottom: 20px;
  }

  .button {
    margin-top: 30px;
  }

  @include media-breakpoint-down(md) {
    .inner-wrp {
      &.page_editor_banner_bottom {
        .content {
          order: 1;
        }

        .img {
          order: 2;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;

    &.type-50-black {
      padding-left: 0;
      padding-right: 0;

      .inner-wrp {
        &.page_editor_banner_right {
          .content {
            padding-left: 32px;
          }
        }
      }

      .img {
        width: 50%;
      }

      .content {
        justify-content: flex-start;
        width: 50%;
        padding-right: 32px;

        h2 {
          font-size: 32px;
          font-weight: 700;
        }
      }
    }

    .inner-wrp {
      flex-direction: row;

      &.page_editor_banner_right {
        .content {
          order: 1;
          padding-left: 0;
          padding-right: 56px;
        }

        .img {
          order: 2;
        }
      }
    }

    .img {
      width: 61.5%;
      margin-left: 0;
      margin-right: 0;
    }

    .title {
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 38.5%;
      padding-left: 56px;
    }
  }
  @include media-breakpoint-up(xl) {
    &.type-50-black {
      .img {
        img {
          aspect-ratio: unset;
        }
      }

      .content {
        padding-top: 82px;

        .html-content {
          max-width: 538px;
        }

        h2 {
          margin-bottom: 20px;
        }
      }
    }

    .html-content {
      font-weight: 400;
      font-size: 20px;
    }
  }
  @include media-breakpoint-up(xxl) {
    &.type-50-black {
      .inner-wrp {
        &.page_editor_banner_right {
          .content {
            padding-left: 64px;
          }
        }
      }

      .content {
        padding-right: 64px;
      }
    }
  }
}

.editor_type_clients {
  position: relative;
  height: 632px;

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }

  .content {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
  }

  .texts {
    padding-top: 76px;

    h2 {
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: $color-white;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: $color-white;
    }

    .button {
      margin-top: 30px;
    }
  }

  .clients-wrp {
    margin-top: auto;
    padding-bottom: 30px;
    margin-right: -20px;

    h3 {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      color: $color-white;
    }
  }

  .clients-list {
    display: flex;
    align-items: center;
    overflow: auto;
    column-gap: 30px;

    .item {
      flex-shrink: 0;
      line-height: 0;

      img {
        filter: grayscale(1);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .texts {
      max-width: 352px;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 700px;

    .texts {
      padding-top: 95px;

      h2 {
        margin-bottom: 6px;
        font-size: 40px;
      }

      p {
        font-size: 22px;
        line-height: 150%;
      }
    }

    .clients-wrp {
      padding-bottom: 60px;
      margin-right: 0;
    }

    .clients-list {
      display: inline-block;
      overflow: visible;

      .item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        text-align: center;
      }
    }
  }
}

.editor_type_subscribe {
  padding-top: 45px;
  padding-bottom: 40px;
  background: $color-grey8;

  h3 {
    margin-bottom: 20px;
  }

  .html-content {
    font-weight: 400;
  }

  .input-group {
    margin-top: 38px;
  }

  .alert {
    margin-top: 16px;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 65px;
    padding-bottom: 75px;

    .subscribe-form {
      display: flex;
      align-items: center;
      column-gap: 50px;

      h3 {
        margin-bottom: 12px;
      }

      .content {
        width: 48%;
      }

      .input-wrp {
        width: 52%;
      }

      .input-group {
        margin-top: 0;
      }
    }
  }
}

.editor_type_contacts {
  .contact-list {
    margin-left: -20px;
    margin-right: -20px;

    .contact-item {
      display: flex;
      flex-direction: column;
      padding: 24px 20px 40px;
      border-bottom: 1px solid $color-grey4;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      &:first-child {
        border-top: 1px solid $color-grey4;
      }

      h6 {
        margin-bottom: 8px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .contact-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-left: 0;
      margin-right: 0;
      border: 1px solid $color-grey4;

      .contact-item {
        border: 1px solid $color-grey4;
        background: $color-white;

        &.mask {
          padding: 0;
          background: $color-grey14;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .contact-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.editor_type_map_contacts {
  margin-bottom: 80px;

  .title {
    font-size: 32px;
    margin-bottom: 24px;
  }

  .inner-wrap {
    margin-left: -20px;
    margin-right: -20px;
    line-height: 0;
  }

  .banner-wrp {
    position: relative;

    img {
      width: 100%;
      height: 232px;
      object-fit: cover;
    }

    .content {
      position: absolute;
      bottom: 24px;
      left: 20px;
      z-index: 1;
      padding-right: 20px;
      color: $color-white;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  iframe {
    width: 100%;
    height: 232px;
  }

  @include media-breakpoint-up(md) {
    .inner-wrap {
      display: flex;
    }

    .banner-wrp {
      width: 50%;
    }

    iframe {
      width: 50%;
    }
  }
  @include media-breakpoint-up(lg) {
    .banner-wrp {
      img {
        height: 464px;
      }

      .content {
        bottom: 32px;
        font-size: 20px;
      }
    }

    iframe {
      height: 464px;
    }
  }
  @include media-breakpoint-up(xl) {
    .inner-wrap {
      margin-left: -32px;
      margin-right: -32px;
    }

    .banner-wrp {
      .content {
        left: 32px;
        padding-right: 32px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .inner-wrap {
      margin-left: -64px;
      margin-right: -64px;
    }

    .banner-wrp {
      .content {
        bottom: 48px;
        left: 64px;
        padding-right: 64px;
      }
    }
  }
}

.editor_type_banner_title {
  position: relative;

  h1 {
    position: absolute;
    bottom: 24px;
    left: 20px;
    color: $color-white;
    font-weight: 700;
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    h1 {
      bottom: 48px;
      font-weight: 500;
    }
  }
  @include media-breakpoint-up(xl) {
    h1 {
      left: 32px;
    }
  }
  @include media-breakpoint-up(xxl) {
    h1 {
      bottom: 110px;
      left: 64px;
    }
  }
}

.editor_type_half_side_text {
  padding-top: 40px;
  padding-bottom: 80px;
  color: $color-black;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @include media-breakpoint-up(lg) {
    .inner-wrp {
      max-width: 50%;
    }
  }
}

.editor_type_title_text {
  padding-top: 80px;
  padding-bottom: 80px;

  h3 {
    margin-bottom: 16px;
    color: $color-black;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  .text {
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    h3 {
      margin-bottom: 0;
      font-size: 40px;
      font-weight: 700;
    }
  }
}

.editor_type_banner3_v2 {
  margin-bottom: 80px;
  overflow: hidden;

  .list {
    display: flex;
    overflow: auto;

    .item {
      position: relative;
      flex-shrink: 0;

      img {
        width: 212px;
        height: 320px;
        object-fit: cover;
      }

      .content {
        position: absolute;
        bottom: 32px;
        left: 0;
        z-index: 1;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;

        h3 {
          margin-bottom: 0;
          color: $color-white;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 133%;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .list {
      overflow: visible;

      .item {
        width: 33.333333%;

        img {
          width: 100%;
          height: auto;
          aspect-ratio: 0.86/1;
        }

        .content {
          position: absolute;
          bottom: 32px;
          left: 0;
          z-index: 1;
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;

          h3 {
            margin-bottom: 15px;
            text-align: left;
          }

          p {
            margin-bottom: 0;
            color: $color-white;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .list {
      overflow: visible;

      .item {
        .content {
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
  }
}

.editor_type_team {
  margin-bottom: 80px;

  h2 {
    margin-bottom: 24px;
  }

  .contact-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 8px;

    .member {
      img {
        width: 100%;
      }

      h6 {
        margin-top: 8px;
        margin-bottom: 4px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }

      span {
        display: block;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .contact-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include media-breakpoint-up(md) {
    .contact-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @include media-breakpoint-up(lg) {
    h2 {
      margin-bottom: 35px;
    }

    .contact-list {
      grid-template-columns: repeat(5, 1fr);
      column-gap: 40px;

      .member {
        h6 {
          margin-top: 20px;
        }
      }
    }
  }
}