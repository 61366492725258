//
// Images & Figures
// --------------------------------------------------


// Figures

.figure {
  padding: $figure-padding;
  border: $figure-border-width solid $figure-border-color;
  @include border-radius($figure-border-radius);
  background-color: $figure-bg;
}

.figure-caption {
  padding: 0 $figure-padding;
}
