.form-item {
  &.form-icon-right {
    position: relative;

    .form-control {
      padding-right: 40px;
    }

    i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 17px;
      color: $color-grey6;
    }
  }

  .form-label {
    margin-bottom: 8px;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
  }

  .form-item-info {
    margin-top: 8px;
    margin-bottom: 0;
    color: $color-grey7;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;

    &.fs14 {
      font-size: 14px;
    }

    a {
      color: $color-grey7;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  .invalid-feedback {
    margin-top: 8px;
    font-size: 12px;
  }

  .show-psw-wrp {
    position: relative;

    input {
      padding-right: 56px;
    }

    .show-psw {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 56px;
      height: 100%;
      padding: 0;
      border: 0;
      background: none;

      &.show {
        i {
          &.fa-eye {
            display: none;
          }

          &.fa-eye-slash {
            display: inline-block;
          }
        }
      }

      i {
        font-size: 24px;
        color: $color-grey6;

        &.fa-eye-slash {
          display: none;
        }
      }
    }
  }
}