#breadcrumb {
  .breadcrumb {
    .breadcrumb-item {
      font-size: 14px;
      font-weight: 500;
      color: $color-grey12;
      line-height: 1.5;

      &:before {
        margin-top: 0;
        font-family: $font-strawford;
        font-size: 14px;
        font-weight: 500;
        color: $color-grey12;
        line-height: 1.5;
      }

      + .breadcrumb-item {
        padding-left: 4px;

        &:before {
          padding-right: 4px;
          content: '/';
        }
      }

      a {
        color: $color-grey12;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .breadcrumb {
      .breadcrumb-item {
        a {
          &:hover {
            color: $color-black;
          }
        }
      }
    }
  }
}