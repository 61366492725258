#side-menu {
  flex: 0 0 auto;
  width: 300px;
  padding: 43px 30px 80px 20px;
  border-right: 1px solid $color-grey4;

  ul {
    li {
      margin-bottom: 16px;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: inline-flex;
        align-items: center;
        color: $color-black;

        &:hover,
        &.active {
          span {
            &:after {
              opacity: 1;
            }
          }
        }

        i {
          margin-right: 10px;
          width: 20px;
          text-align: center;
          font-size: 18px;
          transform: translateY(-2px);
        }

        span {
          position: relative;
          display: inline-block;
          line-height: 1.33;

          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: $color-black;
            opacity: 0;
          }
        }
      }

      .sub {
        margin-top: 16px;
        margin-left: 24px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    padding-left: 32px;
  }
  @include media-breakpoint-up(xxl) {
    width: 360px;
    padding-left: 64px;
  }
}