#orders-success {
  .content {
    padding-top: 20px;
    padding-bottom: 24px;

    h1 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 16px;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    .btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 8px;
    }
  }

  .image {
    img {
      width: 100%;
      height: 488px;
      object-fit: cover;
    }
  }

  .order-info {
    padding-bottom: 40px;
  }

  .order-details {
    margin-bottom: 80px;

    h2 {
      margin-bottom: 3px;
    }

    #cart-items {
      margin-bottom: 16px;

      .cart-item {
        border-top: 0;
        border-bottom: 1px solid $color-grey4;
      }
    }

    #cart-summary {
      font-size: 14px;
    }
  }

  .order-info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 104px;
    padding: 24px;
    margin-bottom: 8px;
    background: $color-grey8;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      width: 30px;
      text-align: center;
      font-size: 22px;
      color: $color-black;
    }

    .info {
      width: 80%;

      h6 {
        margin-bottom: 2px;
        line-height: 150%;
        font-weight: 500;
      }

      p {
        margin-bottom: 0;
        line-height: 150%;
        font-weight: 700;
        color: $color-black;

        a {
          color: $color-black;
          text-decoration-line: underline;

          &:hover {
            text-decoration-line: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 64px;

      h1 {
        margin-bottom: 26px;
      }

      .btns {
        flex-direction: row;
        column-gap: 8px;
        margin-top: 16px;
      }
    }

    .order-info {
      padding-top: 40px;
      padding-bottom: 80px;
      padding-left: 64px;
    }

    .order-info-box {
      min-height: 112px;
      padding: 32px 40px;
    }

    .order-details {
      padding: 40px 64px 80px;
      margin-bottom: 0;

      h2 {
        margin-bottom: 32px;
      }

      #cart-summary {
        font-size: 16px;
      }

      #cart-items {
        padding-bottom: 48px;
        margin-bottom: 32px;
        border-bottom: 1px solid $color-grey4;

        .cart-item {
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 24px;
          border-bottom: 0;

          &:last-child {
            margin-bottom: 0;
          }

          .img {
            width: 124px;
            height: 124px;
          }

          .title {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 700;
          }

          .price {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}