.listing-parent-subs {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  overflow: auto;

  .item {
    position: relative;
    flex-shrink: 0;
    width: 212px;
    height: 320px;
    line-height: 0;

    &.no-img {
      height: auto;

      .content {
        position: static;

        h2 {
          color: $color-dark;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      bottom: 24px;
      left: 0;
      z-index: 1;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      text-align: center;

      h2 {
        margin-bottom: 8px;
        color: $color-white;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    flex-wrap: wrap;
    overflow: visible;

    &.items-2 {
      .item {
        width: 50%;
      }
    }

    .item {
      width: 25%;
      height: 544px;

      .content {
        bottom: 48px;
      }
    }
  }
}