.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-sticky;
  display: flex;
  align-items: center;
  height: 64px;
  background: $color-white;

  .logo {
    line-height: 0;

    img {
      width: 78px;
    }
  }

  .menu-bars {
    font-size: 13px;
    line-height: 0;
    color: $color-black;
  }

  .menu-left-side {
    .top-header-menu {
      display: none;
    }
  }

  .menu-right-side {
    .mrs {
      padding: 5px;
      margin-right: 8px;
    }

    .profile {
      font-size: 17px;
      line-height: 0;
      color: $color-black;
    }
  }

  .search {
    font-size: 16px;
    line-height: 0;
    color: $color-black;
  }

  @include media-breakpoint-up(md) {
    .menu-right-side {
      .mrs {
        margin-right: 12px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: sticky;
    left: unset;
    display: block;
    height: auto;

    .header-wrp {
      padding-top: 11px;
      padding-bottom: 11px;
    }

    .menu-left-side {
      .top-header-menu {
        display: inline-block;

        li {
          display: inline-block;
          margin-right: 32px;

          a {
            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }

      .phone {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: $color-black;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }

    .menu-right-side {
      .mrs {
        position: relative;
        display: inline-block;
        margin-right: 14px;
        padding: 8px;

        &.active, &:hover {
          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 4px;
          left: 50%;
          width: 20px;
          height: 1px;
          background: $color-black;
          transform: translateX(-50%);
          opacity: 0;
        }
      }

      .switch-lang-btn {
        padding-top: 9px;
        padding-bottom: 7px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
        color: $color-black;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .header-wrp {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}