#orders-pay {
  #payment-methods {
    margin-bottom: 32px;
  }

  .payment-option {
    .payment-option-header {
      margin-bottom: 0;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;

      .payment-option-button {
        display: flex;
        align-items: flex-start;
        color: $color-black;

        &:not(.collapsed) {
          .radio-input {
            border-color: $color-black;
            background: $color-black;
          }

          i {
            &.fa-caret-down {
              display: none;
            }

            &.fa-caret-up {
              display: inline-block;
            }
          }
        }

        .title-wrp {
          display: flex;
          flex-direction: column;
          margin-right: 16px;
        }

        .payment-price {
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
        }

        i {
          margin-top: 3px;
          margin-left: auto;
          font-size: 14px;

          &.fa-caret-up {
            display: none;
          }
        }

        .radio-input {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 19px;
          height: 19px;
          margin-top: 1px;
          margin-right: 12px;
          border: 1px solid $color-grey6;
          border-radius: 100%;
          background: $color-white;
          line-height: 0;

          &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background: $color-white;
          }
        }
      }
    }

    .payment-option-content {
      padding-left: 31px;

      .payment-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        margin-top: 8px;

        .payment-type {
          height: 48px;
          padding: 10px;
          border: 1px solid $color-grey4;

          &.active {
            border-color: $color-black;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .payment-desc{
        max-width: 500px;
        margin-top: 8px;
        font-size: 14px;
        line-height: 140%;
        color: $color-black;

        *:last-child{
          margin-bottom: 0;
        }

        .payment-logo{
          margin-bottom: 15px;

          img{
            max-height: 50px;
            max-width: 140px;
          }
        }
      }

      .payment-rules {
        margin-top: 8px;
        font-size: 14px;
        line-height: 140%;
        color: $color-black;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .payment-option {
      .payment-option-content {
        .payment-list {
          grid-template-columns: repeat(3, 1fr);
          max-width: 500px;
        }
      }
    }
  }
}