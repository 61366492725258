.product-list {
  display: flex;
  flex-wrap: wrap;

  .product-element {
    width: 50%;
  }

  @include media-breakpoint-up(sm) {
    .product-element {
      width: 33.33333%;
    }
  }
  @include media-breakpoint-up(md) {
    .product-element {
      width: 25%;
    }
  }
}