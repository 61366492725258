.form-item-check {
  display: flex;
  align-items: flex-start;

  label {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;

    a {
      color: $color-black;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  input {
    display: none;

    &:checked {
      ~ .input {
        border-color: $color-black;
        background: $color-black;

        i {
          display: block;
        }
      }
    }
  }

  .input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 16px;
    border: 1px solid $color-grey6;
    background: $color-white;
    line-height: 0;

    &.filter-color {
      border: 0;

      &.light {
        border: 1px solid $color-grey6 !important;

        i {
          color: $color-black !important;
        }
      }
    }

    i {
      display: none;
      font-size: 9px;
      color: $color-white;
    }
  }
}