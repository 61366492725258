#social-logins {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0;
    border: 1px solid $color-grey4;
    background: $color-white;
    color: $color-dark;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
    transition: all 0.3s ease;

    &:hover {
      border-color: $color-black;
    }

    img {
      width: 24px;
      height: auto;
      margin-right: 8px;
    }

    span {
      display: inline-block;
      transform: translateY(2px);
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    column-gap: 16px;
  }
}