#cart-vouchers {
  &.collapse-vouchers {
    h6 {
      margin-bottom: 0;

      a {
        position: relative;
        display: block;
        color: $color-black;

        &:hover {
          color: $color-black;
        }

        &[aria-expanded="true"] {
          i {
            &.fa-plus {
              display: none;
            }

            &.fa-minus {
              display: inline-block;
            }
          }
        }

        i {
          transition: color 0.3s ease;

          &.fa-minus,
          &.fa-plus {
            position: absolute;
            top: 50%;
            right: -5px;
            z-index: 1;
            padding: 5px;
            transform: translateY(-50%);
            font-size: 13px;
            color: $color-black;
          }

          &.fa-minus {
            display: none;
          }
        }
      }
    }

    form {
      padding-top: 16px;
    }
  }

  h6 {
    margin-bottom: 16px;
  }

  .form-control-wrp {
    display: flex;

    .button {
      min-width: 107px;
    }
  }

  .vouchers-used {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    margin-top: 8px;

    li {
      display: inline-flex;
      align-items: center;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;

      a {
        display: inline-block;
        padding-left: 5px;
        line-height: 0;

        i {
          font-size: 8px;
          color: $color-black;
        }
      }
    }
  }

  .alert {
    margin-top: 8px;
    margin-bottom: 0;
  }
}