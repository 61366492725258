.product-element {
  position: relative;
  flex-shrink: 0;
  border: 1px solid $color-grey4;

  .tags {
    top: 8px;
    left: 8px;
    width: 80%;
  }

  .add-to-wishlist {
    top: 3px;
    right: 3px;
  }

  .product-img {
    display: block;
    line-height: 0;
    text-align: center;

    img {
      height: 172px;
      aspect-ratio: 1.05/1;
      object-fit: contain;
    }
  }

  .details {
    padding: 8px 16px 36px;
  }

  .brand {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.25;
    color: rgba($color-black, 0.5);
  }

  .title {
    margin-top: 3px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.33;

    a {
      color: $color-black;
    }
  }

  .product-price {
    display: flex;
    align-items: center;

    .price {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      color: $color-black;
      white-space: nowrap;

      &.discount {
        color: $color-red;
      }
    }

    .out-of-stock {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      color: $color-black;
    }

    .old-price {
      margin-left: 8px;
      font-weight: 400;
      font-size: 10px;
      text-decoration-line: line-through;
      color: $color-black;
    }
  }

  .colors {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 8px;

    .color {
      display: flex;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      overflow: hidden;

      span {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .add-to-wishlist {
      &.remove-from-wishlist {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 8px;
        right: 8px;
        width: 40px;
        height: 40px;
        padding: 0;
        background: $color-grey8;
      }
    }

    .product-img {
      img {
        height: 344px;
      }
    }

    .details {
      display: grid;
      grid-template-areas:
      "brand brand"
      "title price"
      "colors price";
      gap: 8px 16px;
      padding-bottom: 40px;
    }

    .brand-wrp {
      grid-area: brand;
    }

    .brand {
      font-size: 12px;

      &:hover {
        text-decoration-line: underline;
      }
    }

    .title {
      grid-area: title;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;

      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }

    .product-price {
      grid-area: price;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;

      .price {
        font-size: 16px;
        line-height: 1.33;
      }

      .out-of-stock {
        font-size: 16px;
        line-height: 1.33;
      }

      .old-price {
        margin-top: 2px;
        margin-left: 0;
        font-size: 12px;
      }
    }

    .colors {
      grid-area: colors;
      gap: 8px;
      margin-top: 0;

      .color {
        width: 16px;
        height: 16px;
      }
    }
  }
}