#feedback_page {
  .feedback-group {
    .answers {
      .feedback-table {
        display: grid;
        grid-auto-flow: row;
        row-gap: 10px;

        .answer {
          label {
            display: block;
            padding: 8px 15px;
            color: $white;
            background-color: $gray-600;
            cursor: pointer;
            transition: all .3s;
          }

          input {
            display: none;

            &:checked + label {
              background-color: $primary;
            }
          }
        }
      }
    }
  }

  #product_feedback {
    .group_title {
    }

    .add_review {
      .product-feedback-wrp {
        display: grid;
        grid-template-areas: "product"
                             "feedback";

        .product-element-wrp {
          grid-area: product;
          justify-self: center;
          display: inline-block;

          .product-title {
            position: absolute;
            top: 10px;
            left: 0px;
            padding: 5px 15px;
            max-width: 70%;
            background-color: rgba(255, 255, 255, .7);

            .brand-title {
              display: block;
              color: $gray-600;
            }
          }
        }

        .feedback-wrp {
          grid-area: feedback;

          .common-rating {
            .rating-buttons {
              display: flex;

              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                color: $white;
                background-color: $gray-600;
                transition: all .3s;

                &.active {
                  background-color: $primary;
                }

                &:not(:last-child) {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .review-wrp {
          grid-area: review;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .feedback-group {
      .answers {
        .feedback-table {
          grid-auto-flow: column;
          column-gap: 10px;

          .answer {
            input {
            }
          }
        }
      }
    }
    #product_feedback {
      .group_title {
      }

      .add_review {
        .product-feedback-wrp {
          grid-template-areas: "product feedback";
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 30px;
        }
      }
    }
  }
}