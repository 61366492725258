.bootstrap-select {
  &.dark {
    .btn {
      &.dropdown-toggle {
        border-color: $color-black;
      }
    }
  }

  &.fs14 {
    .btn {
      &.dropdown-toggle {
        padding-top: 13px;
        padding-bottom: 12px;
        font-size: 14px;
      }
    }

    .dropdown-item {
      padding-top: 14px;
      padding-bottom: 13px;
      font-size: 14px;
    }
  }

  .btn {
    &.dropdown-toggle {
      padding: 11px 16px;
      border-radius: 0;
      border-color: $color-grey6;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;

      &:focus {
        outline: none !important;
      }
    }
  }

  .color-wrp {
    position: relative;
    padding-left: 22px;

    .color {
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      overflow: hidden;
      transform: translateY(calc(-50% - 2px));

      span {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .dropdown-menu {
    padding: 0;
    border-bottom: 0;
    border-radius: 0;
    border-color: $color-grey6;
    background: $color-white;
    box-shadow: none;

    li {
      border-bottom: 1px solid $color-grey6;

      a {
        span {
          &.text {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }

  .dropdown-item {
    padding: 12px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    color: $color-black;

    &.active,
    &:hover {
      background: $color-grey5;
    }
  }
}