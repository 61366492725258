#products-listing {
  margin-bottom: 80px;

  #products-and-filters {
    border-left: 1px solid $color-grey4;

    .product-element {
      border: 0;
      border-bottom: 1px solid $color-grey4;
      border-right: 1px solid $color-grey4;
    }
  }

  @include media-breakpoint-down(sm) {
    #products-and-filters {
      .product-element {
        &:nth-child(-n+2) {
          border-top: 1px solid $color-grey4;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    #products-and-filters {
      .product-element {
        &:nth-child(-n+3) {
          border-top: 1px solid $color-grey4;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    #products-and-filters {
      .product-element {
        &:nth-child(-n+4) {
          border-top: 1px solid $color-grey4;
        }
      }
    }
  }
}