#checkout-confirm {
  .confirm-info-box {
    margin-bottom: 16px;
    padding: 20px;
    background: $color-grey8;

    .info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      h2 {
        margin-bottom: 0;
        font-size: 20px;
      }

      .link {
        margin-left: 16px;
      }
    }

    .info-content {
      h6 {
        margin-top: 16px;
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 0;
        color: $color-black;
      }
    }
  }

  #orders-pay {
    margin-top: 30px;
  }

  .checkout-content {
    margin-bottom: 35px;
  }

  @include media-breakpoint-up(md) {
    .confirm-info-box {
      margin-bottom: 8px;
    }
  }
}