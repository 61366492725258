.top-header-menu {
  li {
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: $color-black;
    }
  }

  @include media-breakpoint-up(lg) {
    li {
      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}