.products-listing-header {
  padding-top: 12px;
  padding-bottom: 30px;

  .header-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  h1 {
    margin-bottom: 0;
    color: $color-black;
  }

  .filter-btn {
    display: inline-block;
    margin-left: 15px;
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    i {
      margin-right: 8px;
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }

  .listing-subs {
    display: flex;
    margin-top: 16px;
    overflow: auto;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 40px;
      padding-left: 16px;
      padding-right: 16px;
      margin-right: 8px;
      border: 1px solid $color-grey6;

      &:last-child {
        margin-right: 0;
      }

      .img {
        display: block;
        line-height: 0;

        img {
          aspect-ratio: 2.05/1;
          object-fit: cover;
        }
      }

      .title {
        display: inline-block;
        color: $color-black;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        white-space: nowrap;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 42px;
    padding-bottom: 42px;

    .header-wrp {
      margin-top: 12px;
    }

    .listing-subs {
      align-items: flex-start;
      flex-wrap: wrap;
      row-gap: 20px;
      margin-top: 30px;
      overflow: visible;

      .item {
        flex-direction: column;
        width: 25%;
        height: auto;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border: 0;

        .title {
          margin-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.25;
          white-space: normal;
        }
      }
    }
  }
}