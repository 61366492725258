.product-gallery {
  position: relative;

  .tags {
    top: 8px;
    left: 8px;
    z-index: 1;
    pointer-events: none;

    .tag {
      pointer-events: all;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 16px;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    pointer-events: none;

    button {
      width: 6px;
      height: 6px;
      padding: 0;
      border: 0;
      border-radius: 100%;
      background: $color-grey13;
      transition: all 0.3s ease-in-out;
      pointer-events: all;

      &.tns-nav-active {
        background: $color-black;
      }
    }
  }

  .product-photo {
    display: block;

    img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 1/1;
    }
  }

  @include media-breakpoint-up(md) {
    .product-slider {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .product-photo {
        &:nth-child(n + 3) {
          display: none;
        }

        img {
          aspect-ratio: 0.86/1;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product-slider {
      &.only-2 {
        .product-photo {
          &:nth-child(n + 3) {
            display: none;
          }
        }
      }

      .product-photo {
        &:nth-child(n + 3) {
          display: block;
        }

        &:nth-child(n + 5) {
          display: none;
        }
      }
    }
  }
}