#pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 47px;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .page-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 34px;
      height: 34px;
      background: $color-grey8;
      font-weight: 700;
      line-height: 1;
      color: $color-black;
      transition: all 0.3s ease;

      &.arrow-prev {
        margin-right: 8px;
      }

      &.arrow-next {
        margin-left: 8px;
      }

      &.active {
        background: $color-black;
        color: $color-white;
      }

      .page-link {
        margin-left: 0 !important;
        border: 0 !important;
        background: none;
        box-shadow: none !important;
        white-space: nowrap;

        &:focus {
          color: $color-black;
          background-color: $color-grey8;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }

  .page-sizes {
    .title {
      display: inline-block;
      margin-right: 8px;
      line-height: 1;
      font-weight: 700;
    }

    .size-link {
      display: inline-block;
      padding: 4px 6px;
      line-height: 1;
      color: $color-black;

      &.active {
        text-decoration-line: underline;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 40px;

    .pagination {
      .page-item {
        &:hover:not(.cursor-not-allowed) {
          background: $color-black;
          color: $color-white;

          .page-link {
            color: $color-white;
          }
        }
      }
    }

    .page-sizes {
      .size-link {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}