.site-footer {
  padding-top: 32px;
  padding-bottom: 40px;
  background: $color-dark;

  .logo {
    display: inline-block;
    line-height: 0;

    img {
      width: 91px;
    }
  }

  .content {
    margin-top: 25px;
    margin-bottom: 25px;

    h6 {
      margin-bottom: 10px;
      color: $color-white;
    }

    p {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-white;
    }
  }

  .footer-social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 15px;
    margin-top: 24px;

    .social-item {
      display: inline-block;
      padding: 5px;
      line-height: 0;
      font-size: 16px;
      color: $color-white;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .languages-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 32px;
    margin-top: 24px;

    li {
      a {
        display: inline-flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        text-decoration-line: underline;
        color: $color-white;

        span {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }

  .footer-menu {
    margin-top: 28px;

    .collapse-header {
      position: relative;
      display: block;
      margin: 0;
      padding-top: 18px;
      padding-bottom: 18px;
      color: $color-white;

      &[aria-expanded="true"] {
        i {
          &.fa-minus {
            display: inline-block;
          }

          &.fa-plus {
            display: none;
          }
        }
      }

      i {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        transform: translateY(-50%);
        font-size: 13px;
        color: $color-grey;

        &.fa-minus {
          display: none;
        }
      }
    }

    .menu-col {
      border-bottom: 1px solid $color-grey9;

      &:last-child {
        border-bottom-color: transparent;
      }

      .html-content {
        padding-bottom: 25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $color-white;

        a{
          color: $color-white;
        }
      }

      ul {
        padding-bottom: 25px;

        li {
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.25;
            color: $color-white;

            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }

  .copyright {
    border-top: 1px solid $color-grey9;
    padding-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
    color: $color-white;
  }

  .copyright-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 15px;
    margin-bottom: 8px;

    li {
      display: inline-block;

      a {
        color: $color-white;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;

    .logo-wrp {
      margin-bottom: 26px;
    }

    .content {
      max-width: 420px;
      margin-top: 0;
    }

    .footer-menu {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 32px;
      margin-top: 0;

      .collapse-header {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 12px;

        i {
          display: none !important;
        }
      }

      .menu-list {
        display: block !important;
        height: auto !important;
        overflow: visible !important;
      }

      .menu-col {
        border-bottom: 0;

        ul {
          padding-bottom: 0;
        }
      }
    }

    .copyright {
      margin-top: 25px;
      padding-top: 16px;
      padding-bottom: 25px;
      font-size: 14px;
    }

    .copyright-menu {
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }
}