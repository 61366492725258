.languages-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  display: flex;
  width: 100%;
  height: 100%;
  visibility: hidden;

  &.active {
    visibility: visible;

    .languages-modal-bg {
      opacity: 1;
    }

    .languages-wrp {
      top: 0;
      opacity: 1;
    }
  }

  .languages-modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.5);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .languages-wrp {
    position: relative;
    z-index: 2;
    top: 15px;
    width: 100%;
    margin-top: auto;
    padding-top: 6px;
    padding-bottom: 24px;
    background: $color-white;
    border: 1px solid $color-grey4;
    opacity: 0;
    transition: all 0.3s ease;

    > .close-btn {
      right: 16px;
      top: 14px;
      z-index: 10;
    }
  }

  .languages-menu {
    li {
      border-bottom: 1px solid $color-grey4;

      a {
        display: flex;
        align-items: center;
        padding-top: 19px;
        padding-bottom: 19px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        color: $color-black;

        span {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: unset;
    right: 0;
    width: 360px;
    height: auto;

    .languages-wrp {
      top: -25px;
      padding: 15px 24px;

      > .btn-close {
        right: 22px;
        top: 14px;
      }
    }

    .languages-menu {
      li {
        &:last-child {
          border-bottom: 0;
        }

        a {
          display: inline-flex;

          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}