#delivery-type-options {
  .delivery-item {
    display: block;
    padding: 16px;
    margin-bottom: 8px;
    border: 1px solid $color-grey6;
    color: rgba($color-black, 0.4);
    font-style: normal;
    line-height: 150%;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      border-color: $color-black;
      color: $color-black;
    }

    .delivery-title-wrp {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .delivery-title {
        font-size: 16px;
        font-weight: 700;
      }

      .delivery-price {
        margin-left: 16px;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .delivery-desc {
      display: block;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include media-breakpoint-up(lg) {
    .delivery-item {
      padding: 16px 16px 16px 24px;
    }
  }
}