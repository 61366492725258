.languages-menu {
  li {
    a {
      span {
        display: inline-block;
        border-radius: 100%;
        overflow: hidden;

        img {
          object-fit: cover;
          transform: scale(1.5);
          transform-origin: center center;
        }
      }
    }
  }
}