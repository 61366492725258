.listing-parent-header {
  .img {
    width: 100%;
    aspect-ratio: 1.8/1;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 48px;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    color: $color-black;
  }

  .html-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 400px;

    &.lg-no-img {
      min-height: unset;
    }

    .img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      background-position: right center;
      background-size: auto 100%;
      aspect-ratio: unset;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .content {
      max-width: 480px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    h1 {
      margin-bottom: 16px;
      font-size: 40px;
    }

    .html-content {
      font-size: 16px;
      font-weight: 500;
    }
  }
}