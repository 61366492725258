#cart-detailed {
  padding-top: 8px;
  padding-bottom: 80px;

  .back-link {
    display: inline-flex;
    align-items: center;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    i {
      margin-right: 4px;
      font-size: 14px;
    }
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 13px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    color: $color-black;
  }

  #cart-items {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid $color-grey4;

    .cart-item {
      &:first-child {
        border-top: 0;
      }
    }
  }

  .cart-content {
    .alert {
      margin-top: 16px;
    }
  }

  #cart-summary {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  #cart-vouchers {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-grey4;
  }

  .button-wrp {
    text-align: right;

    .button {
      min-width: 133px;

      i {
        margin-left: 8px;
        font-size: 10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.cart-detailed {
      padding-top: 0;
      padding-bottom: 0;

      .container {
        display: flex;

        .cart-col {
          width: 50%;
        }
      }

      .cart-content {
        padding-top: 44px;
        padding-bottom: 80px;
        border-right: 1px solid $color-grey4;
      }

      .cart-actions {
        padding-top: 44px;
        padding-bottom: 80px;
      }

      .cart-actions-inner {
        max-width: 434px;
        margin-left: auto;
        margin-right: auto;
      }

      .alert-wrp {
        padding-right: 64px;
      }

      #cart-items {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-top: 0;

        .cart-item {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 64px;
          margin-bottom: 24px;
          border-top: 0;

          &:last-child {
            margin-bottom: 0;
          }

          .img {
            width: 124px;
            height: 124px;
          }
        }
      }

      #cart-vouchers {
        padding: 0 0 24px;
        margin-left: 0;
        margin-right: 0;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 32px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &.cart-detailed {
      .container {
        .cart-col {
          padding-left: 32px;
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    &.cart-detailed {
      .container {
        .cart-col {
          padding-left: 64px;
        }
      }
    }
  }
}