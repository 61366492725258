.link {
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  color: $color-black;
  transition: all 0.3s ease;

  &:disabled, .disabled {
    color: $color-grey3;
    cursor: not-allowed;
  }

  &:hover:not(.disabled):not(:disabled) {
    color: $color-dark-grey;
  }

  i {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
  }
}