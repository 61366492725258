.customers-authorize {
  h1 {
    margin-bottom: 26px;
    color: $color-black;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
  }

  #social-logins {
    margin-bottom: 22px;
  }

  .form {
    .button {
      margin-top: 32px;
    }
  }

  .top-text,
  .bottom-text {
    color: $color-black;

    a {
      color: $color-black;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  .top-text {
    margin-bottom: 16px;
  }

  .bottom-text {
    margin-top: 16px;
    margin-bottom: 0;
  }
}