#filters {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.15s ease-in-out;

  &.is_shown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .filters-inner {
    position: relative;
    z-index: 1;
    height: 100%;
    padding-bottom: 110px;
    overflow: auto;
  }

  .close-btn {
    right: 15px;
    top: 14px;
    z-index: 2;
  }

  .filter-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 8px;
    width: 100%;
    padding: 16px 20px;
    background: $color-grey8;
  }

  .filter-wrp {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .total {
    flex-shrink: 0;
    margin-left: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
  }

  .inner-content {
    padding-top: 24px;
  }

  .filter-header {
    display: block;
    margin-bottom: 18px;
    border-bottom: 1px solid $color-grey4;
    padding: 14px 25px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    color: $color-black;
  }

  .filter-title {
    position: relative;
    display: block;
    margin-bottom: 0;
    padding-right: 20px;
    color: $color-black;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.25;
    transition: color 0.3s ease;

    &[aria-expanded="true"] {
      i {
        &.fa-plus {
          display: none;
        }

        &.fa-minus {
          display: inline-block;
        }
      }
    }

    i {
      transition: color 0.3s ease;

      &.fa-minus,
      &.fa-plus {
        position: absolute;
        top: 50%;
        right: -5px;
        z-index: 1;
        padding: 5px;
        transform: translateY(-50%);
        font-size: 13px;
        color: $color-black;
      }

      &.fa-minus {
        display: none;
      }
    }
  }

  .filter-list {
    .filter-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-item-search {
    margin-bottom: 16px;
  }

  .form-item-check {
    label {
      font-size: 14px;
    }

    .input {
      margin-top: 0;
      margin-right: 8px;
    }
  }

  .range-price-slider {
    padding-left: 20px;
    padding-right: 20px;

    .prices-fields {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      margin-left: -20px;
      margin-right: -20px;

      .form-item {
        input {
          width: 120px;
        }

        i {
          font-style: normal;
          line-height: 1;
        }
      }
    }
  }

  #products-listing-filter-selected {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid $color-grey4;

    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .filter-item-selected {
      display: flex;
      align-items: center;
      padding: 5px 12px 6px 16px;
      background: $color-grey8;
      font-size: 14px;
      color: $color-black;
      font-weight: 500;
      white-space: nowrap;

      button {
        margin-left: 4px;
        padding: 0 5px;
        border: 0;
        background: none;
        line-height: 0;

        i {
          font-size: 8px;
          color: $color-black;
        }
      }
    }

    .filter-clear-all {
      padding: 0;
      margin-left: 8px;
      border: 0;
      background: none;
      color: $color-grey7;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      text-decoration-line: underline;
      white-space: nowrap;
      transition: color 0.3s ease;

      &:hover {
        color: $color-black;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    top: unset;
    left: unset;
    z-index: 1;
    height: auto;
    border-top: 1px solid $color-grey4;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transition: none;

    #desktop-filters-list {
      background: $color-grey8;

      .filter-title {
        display: none;
      }

      .filter-wrp {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        max-width: map-get($container-max-widths, 'xl');
      }

      .collapsing {
        transition: none !important;
      }
    }

    .form-item-search {
      max-width: 300px;
    }

    .filter-list {
      display: flex;
      flex-flow: wrap column;
      max-height: 300px;
      column-gap: 32px;
    }

    .range-price-slider {
      max-width: 350px;
    }

    .form-item-check {
      label {
        font-size: 16px;
      }

      .input {
        margin-top: 3px;
      }
    }

    .filters-inner {
      padding-bottom: 0;
      overflow: visible;
    }

    .inner-content {
      padding-top: 30px;
      padding-bottom: 40px;
    }

    #filter {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .filter-inner {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .filter-title {
        font-weight: 500;
      }
    }

    .filter-wrp {
      margin-bottom: 0;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    .filter-title {
      padding-top: 18px;
      padding-bottom: 18px;
      padding-right: 0;

      &[aria-expanded="true"],
      &:hover {
        color: $color-grey13;

        i {
          color: $color-grey13;
        }
      }

      i {
        &.fa-minus,
        &.fa-plus {
          position: static;
          padding: 0;
          margin-left: 5px;
          transform: none;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    #desktop-filters-list {
      .filter-wrp {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    #desktop-filters-list {
      .filter-wrp {
        padding-left: 64px;
        padding-right: 64px;
      }
    }
  }
}