#checkout-steps {
  display: flex;
  align-items: center;

  .step-item {
    margin-right: 12px;
    color: $color-grey6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $color-black;

      &:hover {
        text-decoration-line: underline;
      }
    }

    i {
      display: inline-block;
      margin-left: 12px;
      font-size: 10px;
    }
  }

  @include media-breakpoint-up(xl) {
    .step-item {
      font-size: 16px;
    }
  }
}