.pagination {
  justify-content: center;
  margin-top: 24px;

  .page-link {
    color: $color-black;
  }

  .page-item {
    &.active {
      .page-link {
        background-color: $color-black;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }
}