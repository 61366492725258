#wishlist-list {
  .product-list {
    margin-left: -20px;
    margin-right: -20px;
  }

  .alert-wrp {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .product-element {
    border: 0;
    border-bottom: 1px solid $color-grey4;
    border-right: 1px solid $color-grey4;
  }

  @include media-breakpoint-down(sm) {
    .product-element {
      &:nth-child(-n+2) {
        border-top: 1px solid $color-grey4;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .product-element {
      &:nth-child(-n+3) {
        border-top: 1px solid $color-grey4;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product-element {
      width: 33.33333%;

      &:nth-child(-n+3) {
        border-top: 1px solid $color-grey4;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product-list {
      margin-left: -33px;
      margin-right: -32px;
    }

    .alert-wrp {
      padding-left: 33px;
      padding-right: 32px;
    }
  }
  @include media-breakpoint-up(xxl) {
    .product-list {
      margin-left: -65px;
      margin-right: -64px;
    }

    .alert-wrp {
      padding-left: 65px;
      padding-right: 64px;
    }
  }
}