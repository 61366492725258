.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  > .close-btn {
    right: 15px;
    top: 14px;
    z-index: 10;
  }

  .main-menu-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 40px;
    overflow: auto;
  }

  .submenu {
    position: fixed;
    top: 0;
    left: 24px;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    background: $color-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    overflow: auto;
    transition: all 0.15s ease-in-out;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  .menu-header-wrp {
    border-bottom: 1px solid $color-grey4;
  }

  .menu-header {
    position: relative;
    padding: 14px 25px;

    &.fh {
      height: 48px;
    }

    .menu-title {
      display: block;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.25;
      color: $color-black;

      i {
        margin-right: 10px;
        font-size: 16px;
      }
    }

    .menu-level-back {
      position: absolute;
      top: 13px;
      left: -5px;
      z-index: 1;
      padding: 5px;
      font-size: 12px;
      line-height: 0;
      color: $color-black;
    }
  }

  .main-menu-items {
    margin-top: 18px;

    .profile-menu-items {
      margin-top: 18px;
    }

    .li1 {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .a1 {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $color-black;

        &.logout {
          color: $color-red;
        }

        i:not(.arrow) {
          display: inline-block;
          width: 24px;
          margin-right: 20px;
          text-align: center;
          font-size: 18px;
          transform: translateY(-1px);
        }

        .arrow {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          transform: translateY(-50%);
          font-size: 10px;
        }
      }
    }
  }

  .items2 {
    margin-top: 18px;

    .li2 {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .a2 {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: $color-black;

        .arrow {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          display: inline-block;
          transform: translateY(-50%);
          font-size: 10px;
        }
      }

      .li2-img {
        display: block;
        margin-top: 8px;
        line-height: 0;
      }
    }
  }

  .items3 {
    margin-top: 18px;

    .li3 {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .a3 {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: $color-black;

        .arrow {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          display: inline-block;
          transform: translateY(-50%);
          font-size: 10px;
        }
      }

      .li3-img {
        display: block;
        margin-top: 8px;
        line-height: 0;
      }
    }
  }

  .items4 {
    margin-top: 16px;

    .li4 {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .a4 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $color-black;

        &.highlight {
          font-weight: 500;
          text-decoration-line: underline;

          &:hover {
            color: $color-dark-grey;
          }
        }
      }
    }
  }

  .menu-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    padding-top: 40px;
  }

  .top-header-menu {
    li {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .switch-lang-btn {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 16px;
    background: $color-grey5;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-black;

    .flag {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 100%;
      line-height: 0;
      overflow: hidden;

      img {
        object-fit: cover;
        transform: scale(1.5);
        transform-origin: center center;
      }
    }

    .lng {
      transform: translateY(2px);
    }
  }

  .mb-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mb-phone {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: $color-black;
  }

  @include media-breakpoint-up(xl) {
    position: static;
    flex-direction: row;
    height: auto;
    background: none;
    opacity: 1;
    visibility: visible;

    .main-menu-inner {
      display: block;
      height: auto;
      padding-bottom: 0;
      overflow: visible;
    }

    .main-menu-items-wrp {
      position: relative;
    }

    .submenu {
      position: static;
      width: auto;
      height: auto;
      padding-bottom: 0;
      overflow: visible;
      transition: none;
    }

    .main-submenu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 150px);
      border: 1px solid $color-grey4;
      padding: 32px 40px;
      overflow: auto;
      transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;

      .submenu {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }

      .items2 {
        margin-top: 0;
      }
    }

    .a2,
    .a3,
    .a4 {
      &:hover {
        text-decoration-line: underline;
      }
    }

    .compact {
      .items2 {
        .li2 {
          margin-bottom: 16px;
        }
      }
    }

    .floating {
      &.li1 {
        &:hover {
          .main-submenu {
            position: absolute;

            &.right {
              left: unset !important;
              right: 0;
            }
          }
        }
      }

      .main-submenu {
        position: fixed;
        width: auto;
        padding-bottom: 82px;
      }

      .items2 {
        display: flex;
        column-gap: 30px;

        .li2 {
          flex-shrink: 0;
          width: 160px;
          margin-bottom: 0;

          &.img-col {
            width: 360px;
            padding-top: 39px;

            .a2 {
              display: inline-block;
              padding-right: 0;
              font-size: 16px;
              text-transform: none;

              .arrow {
                position: static;
                margin-left: 8px;
                transform: none;
              }
            }
          }

          .li2-img {
            margin-top: 16px;
          }

          .a2:not(.img-a) {
            position: relative;
            display: inline-block;
            padding-right: 0;
            font-size: 18px;
            text-transform: uppercase;
            text-decoration-line: none;

            &:hover {
              &:after {
                opacity: 1;
              }
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: $color-black;
              opacity: 0;
            }
          }
        }
      }

      .items3 {
        margin-top: 12px;

        .li3 {
          margin-bottom: 16px;

          .a3 {
            display: inline-block;
            padding-right: 0;

            .arrow {
              position: static;
              margin-left: 8px;
              transform: none;
            }
          }
        }
      }

      .items4 {
        margin-top: 10px;

        .li4 {
          margin-bottom: 8px;

          .a4 {
            display: inline-block;
          }
        }
      }
    }

    .fullwidth {
      position: static;

      .main-submenu {
        width: 100%;
        padding-left: 64px;
        padding-right: 64px;
      }

      .inner-container {
        display: flex;
      }

      .items2 {
        flex-shrink: 0;
        width: 296px;
        padding-right: 85px;

        .li2 {
          .a2 {
            font-size: 16px;
            text-transform: uppercase;
            text-decoration-line: none;

            &.active {
              span {
                &:after {
                  opacity: 1;
                }
              }
            }

            span {
              position: relative;
              display: inline-block;

              &:hover {
                &:after {
                  opacity: 1;
                }
              }

              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $color-black;
                opacity: 0;
              }
            }
          }
        }
      }

      .drop-submenu {
        display: flex;
        column-gap: 30px;
        flex-grow: 1;
        min-height: 388px;
      }

      .items3 {
        flex-shrink: 0;
        width: 160px;
        margin-top: 0;

        .li3 {
          .a3 {
            display: inline-block;
            padding-right: 0;

            .arrow {
              position: static;
              margin-left: 8px;
              transform: none;
            }
          }
        }
      }

      .items4 {
        margin-top: 10px;

        .li4 {
          margin-bottom: 8px;

          .a4 {
            display: inline-block;
          }
        }
      }

      .img-col {
        width: 404px;
        padding-left: 44px;

        .li3-img {
          display: block;
          margin-top: 16px;
          line-height: 0;
        }

        .a3 {
          display: inline-block;
          font-size: 16px;
          text-transform: none;

          .arrow {
            position: static;
            margin-left: 8px;
            transform: none;
          }
        }
      }
    }

    .main-menu-items {
      display: flex;
      margin-top: 0;
      background: $color-dark;

      .li1 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        flex: 1 0 0;
        border-right: 1px solid $color-grey11;
        transition: all 0.3s ease;

        &:last-child {
          border-right-color: transparent;
        }

        &:hover {
          background: $color-grey10;

          .main-submenu {
            left: 0;
            opacity: 1;
            visibility: visible;
            pointer-events: all;
          }
        }

        .a1 {
          display: block;
          width: 100%;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-right: 0;
          text-align: center;
          color: $color-white;

          i:not(.arrow) {
            width: auto;
            margin-right: 8px;
            color: $color-white;
            transform: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .main-menu-items {
      .li1 {
        border-left: 1px solid $color-grey11;

        + li {
          margin-left: -1px;
        }

        &:nth-child(2) {
          margin-left: 0;
        }

        &:last-of-type {
          border-right-color: $color-grey11;
        }
      }
    }
  }
}