#customer {
  .customer-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 321px;
    background: $color-grey14;

    img {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      max-width: 100%;
      height: 100%;
    }
  }

  .customers-login{
    max-width: 996px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers-prelogin{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers_remind_password{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers_register{
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .content-wrp {
    display: flex;
  }

  .content {
    flex: 1 0 0;
    padding-top: 24px;
    padding-bottom: 80px;

    h1 {
      margin-bottom: 16px;
      color: $color-black;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.33;
    }

    .bottom-text {
      margin-top: 16px;
      margin-bottom: 0;
      color: $color-black;
    }

    .button {
      margin-top: 18px;
    }
  }

  @include media-breakpoint-up(lg) {
    .content {
      padding-top: 42px;
      padding-left: 20px;
      padding-right: 20px;
      overflow: hidden;

      &.no-side {
        padding-left: 0;
      }

      h1 {
        margin-bottom: 27px;
        font-size: 32px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .content {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  @include media-breakpoint-up(xxl) {
    .content {
      padding-left: 64px;
      padding-right: 64px;

      &.no-side {
        padding-left: 0;
      }
    }
  }
}