#size-tables {
  h4 {
    margin-bottom: 30px;
    color: $color-black;
  }

  h6 {
    margin-bottom: 22px;
    color: $color-black;
  }

  .html-content {
    font-size: 16px;
    line-height: 150%;

    p {
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }

  table {
    width: calc(100% + 40px);
    margin-bottom: 50px;
    margin-left: -20px;
    border-collapse: collapse;
    table-layout: fixed;

    tr {
      &:nth-child(even) {
        background: $color-grey8;
      }
    }

    thead td,
    th {
      padding-top: 6px;
      padding-bottom: 6px;
      color: $color-black;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.33;
      text-transform: uppercase;
      vertical-align: bottom;
    }

    td {
      padding-top: 15px;
      padding-bottom: 14px;
      color: $color-black;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.33;
    }
  }

  @include media-breakpoint-up(md) {
    .html-content {
      img {
        float: right;
        width: auto;
        max-width: 300px;
        margin-left: 50px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    h4 {
      padding-left: 64px;
      padding-right: 64px;
      padding-bottom: 16px;
      margin-left: -64px;
      margin-right: -64px;
      border-bottom: 1px solid $color-grey4;
    }

    table {
      width: 100%;
      margin-left: 0;

      thead td,
      th {
        padding-top: 10px;
        padding-bottom: 14px;
      }

      thead td,
      th {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}