.cart-item {
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid $color-grey4;

  .img {
    display: block;
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    margin-right: 16px;
    border: 1px solid $color-grey4;
    background: $color-white;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .details {
    flex-grow: 1;
  }

  .details-content {
    display: flex;
    justify-content: space-between;
  }

  .price-wrp {
    margin-left: 16px;
    text-align: right;
  }

  .title {
    display: block;
    margin-bottom: 4px;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .price {
    display: block;
    margin-bottom: 4px;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  }

  .amount {
    display: block;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .types {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;

    li {
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    .quantity-control {
      display: flex;
      align-items: center;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;

      .quantity-btn {
        padding: 5px 6px;

        &.minus {
          padding-left: 0;
        }
      }
    }

    .remove-item {
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }
}