#cart-summary {
  color: $color-black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  .summary {
    .row {
      margin-bottom: 8px;
    }
  }

  .total {
    font-weight: 700;
  }
}