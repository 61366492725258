.alert {
  padding: 9px 16px;
  margin-bottom: 16px;
  border-radius: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  text-align: left;

  &.alert-danger {
    color: $color-red;
    border-color: $color-red;
    background: rgba($color-red, 0.2);

    a {
      color: $color-red;
    }
  }

  &.alert-dismissible {
    padding-right: 56px;

    .btn-close {
      width: 40px;
      height: 100%;
      padding: 0;
      border-radius: 0;
      background: none;
      font-size: 16px;
      line-height: 1;
      color: $color-black;
      opacity: 1;
    }
  }

  a {
    color: #3bc188;
    text-decoration-line: underline;
  }
}