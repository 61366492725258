#products-detailed {
  .product-banner {
    position: relative;

    img {
      width: 100%;
      aspect-ratio: 1.27/1;
      object-fit: cover;
    }

    .container {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate3d(-50%, -50%, 0);

      .h2 {
        margin-bottom: 0;
        color: $color-white;
        line-height: 1.33;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .product-description {
    padding-top: 80px;
    padding-bottom: 80px;

    &.no-top-banner {
      padding-top: 0;
    }

    h3 {
      margin-bottom: 26px;
      font-size: 24px;
      color: $color-black;
    }

    h6 {
      margin-bottom: 8px;
    }

    .html-content {
      font-size: 16px;
      line-height: 150%;
    }

    .product-details {
      margin-bottom: 24px;

      p {
        margin-bottom: 0;
      }
    }

    .description {
      p {
        margin-bottom: 24px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .product-banner {
      height: 464px;

      img {
        height: 100%;
        aspect-ratio: unset;
      }

      .h2 {
        max-width: 500px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product-content {
      display: flex;
      align-items: flex-start;

      .product-gallery {
        width: 60%;
      }

      #products-add2cart {
        width: 40%;
      }
    }

    .product-description {
      padding-top: 80px;
      padding-bottom: 80px;

      &.no-top-banner {
        padding-top: 80px;
      }

      h3 {
        width: 100%;
        font-size: 32px;
      }

      .container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 48px;
      }

      .product-details {
        width: 370px;
      }

      .description {
        width: 440px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .product-content {
      .product-gallery {
        width: 66.6666%;
      }

      #products-add2cart {
        width: 33.3333%;
      }
    }
  }
}