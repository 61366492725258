.form-control {
  padding: 13px 16px;
  border: 1px solid $color-grey6;
  border-radius: 0;
  box-shadow: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: $color-black;

  &::placeholder {
    font-weight: 400;
    color: $color-grey7;
  }

  &:focus {
    border-color: $color-black;
    color: $color-black;
    box-shadow: none;
  }

  &.h40 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &.h40-fixed {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  @include media-breakpoint-up(md) {
    &.h40 {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }
}