.modal-header {
  height: 48px;
  padding: 0;
  border-bottom: 0;
  border-radius: 0;

  .close-btn {
    right: 15px;
    top: 14px;
    z-index: 2;
  }
}

.modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.modal-body {
  padding: 0 20px 20px;
}

.modal-backdrop {
  background-color: $color-black;
}

.size-tables-modal {
  max-width: 1120px;

  .modal-body {
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(lg) {
    .modal-header {
      height: 0;
      border-bottom: 0;

      .close-btn {
        right: 25px;
        top: 25px;
      }
    }

    .modal-body {
      padding-top: 40px;
      padding-left: 64px;
      padding-right: 64px;
    }
  }
}

.modal-auth {
  max-width: 562px;

  .modal-body {
    @include media-breakpoint-up(xl) {
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 64px;
      padding-right: 64px;
    }
  }
}

.modal-login {
  max-width: 996px;

  @include media-breakpoint-up(lg) {
    .modal-content {
      position: relative;
    }

    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      pointer-events: none;

      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 100%;
        background: $color-white;
        pointer-events: all;
      }
    }

    .modal-body {
      display: flex;
      padding: 0;
    }
  }
}

.modal-prelogin {
  .customers-login {
    .image {
      display: none !important;
    }

    .content {
      width: 100%;
      padding: 0;
    }
  }
}