html, body {
  height: auto;
}

body {
  padding-top: 64px !important;
  font-family: $font-strawford;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  @include media-breakpoint-up(lg) {
    &#type_cart {
      padding-top: 0 !important;
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 0 !important;
  }
}

.container {
  @include media-breakpoint-up(xl) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.container-fluid {
  max-width: 1440px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.25;
  color: $color-black;

  @include media-breakpoint-up(md) {
    font-size: 42px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 64px;
  }
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.25;
  color: $color-black;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 40px;
  }
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  color: $color-black;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 32px;
  }
}

h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  color: $color-black;

  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }
}

h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  color: $color-black;
}

.form {
  .form-item {
    margin-bottom: 14px;
  }
}

.html-content {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: $color-black;

  *:first-child {
    margin-top: 0 !important;
  }

  *:not(.page_editor_template):last-child {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 24px;
  }

  a:not(.link):not(.button) {
    color: $color-black;
    text-decoration-line: underline;

    &:hover {
      text-decoration-line: none;
    }
  }

  strong {
    font-weight: 700;
  }

  table {
    width: 100% !important;
    height: auto !important;
  }
}


#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;
    -webkit-animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }

    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
      -webkit-animation: d 1.8s linear infinite;
    }

    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      -webkit-animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
      -webkit-animation-delay: -0.9s;
    }
  }
}

.order-status {
  &-0 {
    color: $color-red;
  }

  &-1 {
    color: $color-blue;
  }

  &-2,
  &-3 {
    color: $color-yellow;
  }

  &-4,
  &-5 {
    color: $color-green;
  }
}

.tooltip{
  z-index: $zindex-dropdown;
}