.header-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h2, h3 {
    margin-bottom: 0;
    color: $color-black;
  }

  a {
    margin-left: 15px;
    white-space: nowrap;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
    margin-bottom: 28px;

    a {
      transform: translateY(3px);
      margin-left: 32px;
    }
  }
}