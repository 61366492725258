.tags {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  .tag {
    display: inline-block;
    padding: 3px 8px;
    background: var(--tag-color);
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 1.25;
    text-transform: uppercase;
    color: $color-white;
  }

  @include media-breakpoint-up(lg) {
    .tag {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 12px;
      line-height: 1.35;
    }
  }
}