.content-boxes {
  display: grid;
  row-gap: 32px;
  margin-bottom: 80px;

  .box-item {
    h4 {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%
    }

    p {
      margin-bottom: 8px;
    }

    .box-link {
      font-size: 14px;
    }
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 50px;
  }
  @include media-breakpoint-up(lg) {
    column-gap: 137px;
    row-gap: 42px;
    max-width: 590px;

    .box-item {
      h4 {
        font-size: 24px;
      }
    }
  }
}