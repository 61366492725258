#products-add2cart {
  padding: 22px 20px 80px;

  .brand-wish {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brand {
      color: $color-grey13;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25;
    }

    .add-to-wishlist {
      position: static;
      padding-right: 0;
      margin-left: auto;

      i {
        font-size: 20px;
      }
    }
  }

  .product-code {
    display: block;
    margin-top: 8px;
    color: $color-grey13;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25;
  }

  .product-title {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.33;
    color: $color-black;
  }

  .product-price {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .price {
      display: inline-block;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;

      &.discount {
        color: $color-red;
      }
    }

    .old-price {
      display: inline-block;
      margin-left: 8px;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: line-through;
    }
  }

  .short-desc {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 150%;

    .link {
      display: inline-block;
      margin-top: 6px;
      font-size: 14px;
    }
  }

  .product-sizes {
    text-align: right;
    margin-bottom: 8px;
  }

  .product-options {
    display: flex;
    justify-content: space-between;

    .dropdown {
      width: calc(50% - 4px);
    }
  }

  .product-add-wrp {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    .alert {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 0;
    }

    .select-amount {
      width: 72px;
    }

    .button {
      flex-grow: 1;
    }
  }

  .product-quantity-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .link {
      margin-left: 15px;
      font-size: 14px;
    }
  }

  .product-quantity-info {
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    color: #000;

    .status {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-top: -1px;
      margin-right: 4px;
      border-radius: 100%;
      background: $color-green;

      &.red {
        background: $color-red;
      }
    }
  }

  .benefits {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4px;
    margin-top: 32px;
    padding: 25px 6px;
    background: $color-grey8;

    .item {
      text-align: center;

      i {
        color: $color-black;
        font-size: 17px;
      }

      span {
        display: block;
        margin-top: 5px;
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.33;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 64px;
    padding-right: 64px;
  }
}