#delivery-options {
  #delivery-info {
    margin-bottom: 26px;
  }

  #delivery-type-options {
    margin-bottom: 38px;
  }

  @include media-breakpoint-up(lg) {
    #delivery-info {
      margin-bottom: 32px;
    }

    #delivery-type-options {
      margin-bottom: 75px;
    }
  }
}