.form-item-radio {
  display: flex;
  align-items: flex-start;

  label {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;

    a {
      color: $color-black;
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  input {
    display: none;

    &:checked {
      ~ .input {
        border-color: $color-black;
        background: $color-black;

      }
    }
  }

  .input {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 19px;
    height: 19px;
    margin-top: 1px;
    margin-right: 16px;
    border: 1px solid $color-grey6;
    border-radius: 100%;
    background: $color-white;
    line-height: 0;

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: $color-white;
    }
  }
}