.become-customer {
  padding-top: 44px;
  padding-bottom: 22px;
  border-bottom: 1px solid $color-grey9;
  background: $color-dark;

  h3 {
    margin-bottom: 28px;
    color: $color-white;
  }

  .benefits {
    .item {
      margin-bottom: 32px;
      color: $color-white;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        font-size: 22px;
      }

      h6 {
        margin-top: 16px;
        margin-bottom: 10px;
        color: $color-white;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 82px;
    padding-bottom: 40px;

    h3 {
      margin-bottom: 30px;
    }

    .benefits {
      display: flex;
      column-gap: 50px;

      .item {
        max-width: 250px;

        h6 {
          margin-bottom: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .benefits {
      column-gap: 100px;

      .item {
        max-width: 300px;
      }
    }
  }
}