.add-to-wishlist:not(.link) {
  position: absolute;
  z-index: 1;
  display: inline-block;
  padding: 5px;
  line-height: 0;

  .icon-heart-filled {
    display: none;
  }

  &.active {
    .icon-heart {
      display: none;
    }

    .icon-heart-filled {
      display: inline-block;
    }
  }

  i {
    font-size: 13px;
    line-height: 1;
    color: $color-black;
  }

  @include media-breakpoint-up(lg) {
    i {
      font-size: 18px;
    }
  }
}