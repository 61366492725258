.close-btn {
  position: absolute;
  z-index: 1;
  padding: 5px;
  border: 0;
  background: 0;
  font-size: 11px;
  line-height: 0;
  color: $color-black;
  transition: all 0.3s ease;
}