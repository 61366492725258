#checkout {
  position: relative;
  padding-top: 20px;
  padding-bottom: 133px;

  #checkout-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    padding-left: 20px;
    padding-right: 20px;
    background: $color-white;

    .logo {
      line-height: 0;

      img {
        width: 78px;
      }
    }

    .left-col {
      display: flex;
      align-items: center;
    }

    #checkout-steps {
      margin-left: 30px;
    }

    .phone {
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;

      &:hover {
        text-decoration-line: underline;
      }
    }

    .safe-shopping {
      display: inline-flex;
      align-items: center;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;

      i {
        margin-right: 4px;
      }
    }
  }

  .info-text {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 150%;
    color: $color-black;
  }

  #checkout-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 20px 18px;
    border-top: 1px solid $color-grey4;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        display: inline-block;
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }

        a {
          color: $color-black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3;

          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }

    .copyright {
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
    }
  }

  .h4 {
    margin-bottom: 27px;
  }

  .button {
    i {
      margin-left: 8px;
      font-size: 10px;
    }
  }

  .collapse-cart-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    margin-bottom: 0;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid $color-grey4;
    border-bottom: 1px solid $color-grey4;
    background: $color-grey8;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    &[aria-expanded="true"] {
      i {
        &.fa-caret-down {
          display: none;
        }

        &.fa-caret-up {
          display: inline-block;
        }
      }
    }

    i {
      margin-left: 16px;
      font-size: 14px;

      &.fa-caret-up {
        display: none;
      }
    }
  }

  #cart-items-wrp {
    #cart-items {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      background: $color-grey8;

      .cart-item {
        &:first-child {
          border-top: 0;
        }
      }
    }
  }

  #cart-summary {
    margin-top: 18px;
    margin-bottom: 40px;
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    #checkout-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      ul {
        order: 2;
        margin-left: 16px;
      }

      .copyright {
        order: 1;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 0;

    .checkout-inner {
      display: flex;
      min-height: 100vh;
    }

    #checkout-footer {
      align-items: flex-start;
      padding-left: 64px;
      padding-right: 64px;

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .checkout-col {
      width: 50%;
      padding: 112px 64px 145px;

      &.checkout-order-details {
        background: $color-grey8;
      }

      &.checkout-content {
        position: relative;

        .button {
          margin-top: 32px;
        }
      }
    }

    #cart-summary {
      font-size: 16px;
    }

    .collapse-cart-items {
      display: block;
      height: auto;
      margin-bottom: 34px;
      border: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      font-size: 24px;
      line-height: 1.25;
      cursor: text;
    }

    #cart-items-wrp {
      display: block !important;
      height: auto !important;
      overflow: visible !important;

      #cart-items {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .cart-item {
        border-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        .img {
          width: 124px;
          height: 124px;
        }

        .title {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 700;
        }

        .price {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    #cart-summary {
      margin-top: 48px;
      margin-bottom: 0;
      padding-top: 32px;
      border-top: 1px solid $color-grey4;
    }

    .button {
      min-width: 133px;
    }

    #checkout-header {
      position: absolute;
      z-index: 1;
      height: 80px;
      padding-left: 32px;
      padding-right: 32px;
      background: none;

      .logo {
        img {
          width: 87px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    #checkout-header {
      #checkout-steps {
        margin-left: 56px;
      }
    }

    #checkout-footer {
      align-items: center;

      ul {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}