.button {
  display: inline-block;
  padding: 17px 24px;
  border: 0;
  background: $color-black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: $color-white;
  text-decoration: none;
  transition: all 0.3s ease;
  appearance: none;

  &:hover:not(.disabled):not(:disabled) {
    background: $color-dark-grey;
    color: $color-white;
  }

  &.h40 {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &.h40-fixed {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &.bordered {
    background: transparent;
    border: 1px solid $color-black;
    color: $color-black;

    &:hover:not(.disabled):not(:disabled) {
      background: $color-dark-grey;
      border: 1px solid $color-dark-grey;
    }

    &.white {
      background: transparent;
      border: 1px solid $color-white;
      color: $color-white;
    }

    &:disabled, &.disabled {
      background: transparent;
      border: 1px solid $color-grey2;
      color: $color-grey2;
    }
  }

  &.white {
    background: $color-white;
    color: $color-black;
  }

  &:disabled, &.disabled {
    background: $color-grey;
    color: $color-grey2;
    cursor: not-allowed;
  }

  @include media-breakpoint-up(md) {
    &.h40 {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}