#orders-detailed {
  .top-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back {
      display: inline-flex;
      align-items: center;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;

      i {
        font-size: 14px;
        margin-right: 4px;
      }
    }

    .status {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
    }
  }

  h1 {
    margin-top: 14px;
  }

  .order-steps {
    position: relative;
    margin-top: 50px;
    margin-bottom: 70px;

    &.progress {
      &-1 {
        .line {
          &:after {
            width: 25%;
          }
        }

        .point-1 {
          span {
            &:after {
              background: $color-black;
            }
          }
        }
      }

      &-2 {
        .line {
          &:after {
            width: 50%;
          }
        }

        .point-1,
        .point-2 {
          span {
            &:after {
              background: $color-black;
            }
          }
        }
      }

      &-3 {
        .line {
          &:after {
            width: 75%;
          }
        }

        .point-1,
        .point-2,
        .point-3 {
          span {
            &:after {
              background: $color-black;
            }
          }
        }
      }

      &-4 {
        .line {
          &:after {
            width: 100%;
          }
        }

        .point-1,
        .point-2,
        .point-3,
        .point-4 {
          span {
            &:after {
              background: $color-black;
            }
          }
        }
      }
    }

    .line {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 3px;
      background: $color-grey4;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: $color-black;
      }
    }

    .status-point {
      position: absolute;
      top: -3px;
      z-index: 2;
      display: block;
      width: 25%;

      &.point {
        &-1 {
          left: 0;
        }

        &-2 {
          left: 25%;
          padding-left: 10px;
          text-align: center;
        }

        &-3 {
          left: 50%;
          padding-left: 18px;
          text-align: center;
        }

        &-4 {
          left: 75%;
          text-align: right;
        }
      }

      span {
        position: relative;
        display: inline-block;
        padding-top: 20px;
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.33;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 5px);
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: $color-grey4;
        }
      }
    }
  }

  .order-details {
    display: grid;
    row-gap: 45px;

    .details-block {
      h6 {
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 0;
        color: $color-black;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 171%;

        .button {
          margin-top: 8px;

          i {
            margin-right: 8px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .order-summary {
    margin-top: 48px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid $color-grey4;

    h2 {
      margin-bottom: 3px;
      color: $color-black;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    #cart-items {
      .cart-item {
        border-top: 0;
        border-bottom: 1px solid $color-grey4;
      }
    }

    #cart-summary {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  #orders-pay {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $color-grey4;

    h2 {
      margin-bottom: 16px;
    }

    #payment-methods {
      margin-bottom: 20px;
    }

    .button {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-right: -32px;

    h1 {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .status {
        margin-left: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
      }
    }

    .top-info {
      max-width: 656px;
    }

    .order-steps {
      max-width: 656px;
      margin-top: 45px;
      margin-bottom: 85px;

      .status-point {
        &.point {
          &-2 {
            padding-left: 0;
          }

          &-3 {
            padding-left: 35px;
          }
        }
      }
    }

    #orders-pay {
      max-width: 656px;
      padding-top: 48px;
      padding-bottom: 48px;
    }

    .order-details {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 64px;
      max-width: 505px;
    }

    .order-summary {
      margin-top: 80px;
      margin-left: -32px;
      margin-right: 0;
      padding-top: 32px;
      padding-left: 32px;
      padding-right: 0;

      h2 {
        margin-bottom: 25px;
        font-size: 24px;
      }

      #cart-items {
        max-width: 656px;

        .cart-item {
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 24px;
          border-bottom: 0;

          &:last-child {
            margin-bottom: 0;
          }

          .img {
            width: 124px;
            height: 124px;
          }

          .title {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 700;
          }

          .price {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      #cart-summary {
        max-width: 656px;
        border-top: 1px solid $color-grey4;
        margin-top: 48px;
        margin-bottom: 32px;
        padding-top: 32px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    margin-right: -64px;

    .order-summary {
      margin-left: -64px;
      padding-left: 64px;
    }
  }
}