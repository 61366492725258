// FOR COLORS DO NOT OVERRIDE $theme-colors ARRAY
// OVERRIDE VARIABLES cartzilla/_variables.scss 28-36 lines

$icons-font-path: '../../../public/fonts/cartzilla';

$font-strawford: 'Strawford';
$font-lato: 'Lato', sans-serif;

$color-white: #FFFFFF;
$color-black: #000000;
$color-dark: #212121;
$color-dark-grey: #454545;
$color-red: #ED5858;
$color-green: #219653;
$color-grey: #E2E2E2;
$color-grey2: #B5B5B5;
$color-grey3: #B4B4B4;
$color-grey4: #DDDDDD;
$color-grey5: #F5F5F5;
$color-grey6: #B3B3B3;
$color-grey7: #999899;
$color-grey8: #F7F7F7;
$color-grey9: #595959;
$color-grey10: #5C5C5C;
$color-grey11: #4d4d4d;
$color-grey12: #898989;
$color-grey13: #888888;
$color-grey14: #F9F9FB;
$color-yellow: #FF9B43;
$color-blue: #055160;

$grid-breakpoints: (
        xxs: 0,
        xs: 360px,
        sm: 500px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
);

$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1440px
);

$spacers: (
        0: 0,
        1: 5px,
        2: 10px,
        3: 15px,
        4: 20px,
        5: 25px,
        6: 30px,
        7: 32px,
        8: 64px,
);

$grid-gutter-width: 2.5rem;

$gutters: (
        0: 0,
        1: 8px,
);