.tns-outer {
  position: relative;

  .tns-controls {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;
    transform: translateY(-50%);

    button {
      width: 40px;
      height: 40px;
      padding: 0;
      border: 0;
      background: $color-grey5;
      font-size: 10px;
      pointer-events: all;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0;
      }

      &:hover {
        background: $color-black;

        i {
          color: $color-white;
        }
      }

      i {
        color: $color-black;
        transition: all 0.3s ease;
      }
    }
  }
}

.slider-nav {
  position: absolute;
  z-index: 2;
  width: 100%;
  pointer-events: none;

  .slider-arrow-btn {
    padding: 5px 6px;
    border: 0;
    background: none;
    font-size: 10px;
    line-height: 1;
    color: $color-white;
    pointer-events: all;
  }

  .slider-nav-counter {
    display: inline-flex;
    align-items: center;

    span {
      display: inline-block;
      width: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: $color-white;
    }
  }
}