.content-accordion {
  margin-bottom: 80px;

  .content-accordion-item {
    border-bottom: 1px solid $color-grey4;

    .content-accordion-header {
      position: relative;
      margin-bottom: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
        font-family: "Font Awesome 6 Pro";
        font-weight: 400;
        content: "\2b";
        font-size: 14px;
        transform: translateY(-50%);
      }

      &.collapsed {
        &:after {
          content: "\f068";
        }
      }
    }

    .content-accordion-body {
      padding-bottom: 16px;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}