.profile-menu-modal {
  position: absolute;
  top: -25px;
  right: 0;
  z-index: $zindex-modal-backdrop;
  width: 360px;
  padding: 30px 24px;
  border: 1px solid $color-grey4;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.active {
    top: 0;
    opacity: 1;
    visibility: visible;
  }

  .close-btn {
    right: 22px;
    top: 14px;
  }

  .user-name {
    display: block;
    padding-bottom: 16px;
    padding-right: 25px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-grey4;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: $color-black;
  }

  .profile-menu-items {
    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $color-black;

        &:hover {
          text-decoration-line: underline;
          color: $color-dark-grey;
        }

        &.logout {
          text-decoration-line: underline;
        }

        i {
          display: none;
        }
      }
    }
  }
}