//
// Social buttons
// --------------------------------------------------


// Base styles

.btn-social {
  display: inline-block;
  width: $btn-social-size;
  height: $btn-social-size;
  padding: 0;
  border: $btn-social-border-width solid transparent;
  transition: $btn-social-transition;
  @include border-radius($btn-social-border-radius);
  background-color: $btn-social-dark-bg;
  color: $btn-social-dark-color;
  font-size: $btn-social-font-size;
  text: {
    align: center;
    decoration: none !important;
  }

  > i {
    line-height: $btn-social-size - .125rem;
  }

  &:hover {
    color: $btn-social-dark-hover-color;
  }

  &:focus {
    outline: none;
  }

  &:active {
    transition: none;
    box-shadow: none !important;
  }

  &.bs-light {
    background-color: $btn-social-light-bg;
    color: $btn-social-light-color;

    &:hover {
      background-color: $btn-social-light-hover-bg !important;
      box-shadow: none !important;
    }
  }
}


// Sizing

.bs-lg {
  width: $btn-social-size-lg;
  height: $btn-social-size-lg;
  @include border-radius($btn-social-border-radius-lg);
  font-size: $btn-social-font-size-lg;

  > i {
    line-height: $btn-social-size-lg - .125rem;
  }
}

.bs-sm {
  width: $btn-social-size-sm;
  height: $btn-social-size-sm;
  @include border-radius($btn-social-border-radius-sm);
  font-size: $btn-social-font-size-sm;

  > i {
    line-height: $btn-social-size-sm - .125rem;
  }
}


// Outline variant

.bs-outline {
  border-color: $btn-social-dark-border-color;
  background-color: transparent;

  &.bs-light {
    border-color: $btn-social-light-border-color;
    background-color: transparent;
  }

  &:hover {
    border-color: transparent;
  }
}


// Brand colors

.bs-facebook:hover {
  background-color: #3b5998;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#3b5998, .9);

  &.bs-light {
    color: #3b5998;
  }
}

.bs-twitter:hover {
  background-color: #1da1f2;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#1da1f2, .9);

  &.bs-light {
    color: #1da1f2;
  }
}

.bs-instagram:hover {
  background-color: #5851db;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#5851db, .9);

  &.bs-light {
    color: #5851db;
  }
}

.bs-google:hover {
  background-color: #ea4335;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#ea4335, .9);

  &.bs-light {
    color: #ea4335;
  }
}

.bs-linkedin:hover {
  background-color: #0077b5;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#0077b5, .9);

  &.bs-light {
    color: #0077b5;
  }
}

.bs-pinterest:hover {
  background-color: #bd081c;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#bd081c, .9);

  &.bs-light {
    color: #bd081c;
  }
}

.bs-tumblr:hover {
  background-color: #35465c;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#35465c, .9);

  &.bs-light {
    color: #35465c;
  }
}

.bs-behance:hover {
  background-color: #1769ff;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#1769ff, .9);

  &.bs-light {
    color: #1769ff;
  }
}

.bs-dribbble:hover {
  background-color: #ea4c89;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#ea4c89, .9);

  &.bs-light {
    color: #ea4c89;
  }
}

.bs-vk:hover {
  background-color: #45668e;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#45668e, .9);

  &.bs-light {
    color: #45668e;
  }
}

.bs-odnoklassniki:hover {
  background-color: #ed812b;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#ed812b, .9);

  &.bs-light {
    color: #ed812b;
  }
}

.bs-skype:hover {
  background-color: #00aff0;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#00aff0, .9);

  &.bs-light {
    color: #00aff0;
  }
}

.bs-hangouts:hover {
  background-color: #0f9d58;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#0f9d58, .9);

  &.bs-light {
    color: #0f9d58;
  }
}

.bs-messenger:hover {
  background-color: #0084ff;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#0084ff, .9);

  &.bs-light {
    color: #0084ff;
  }
}

.bs-viber:hover {
  background-color: #59267c;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#59267c, .9);

  &.bs-light {
    color: #59267c;
  }
}

.bs-telegram:hover {
  background-color: #0088cc;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#0088cc, .9);

  &.bs-light {
    color: #0088cc;
  }
}

.bs-youtube:hover {
  background-color: #ff0000;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#ff0000, .9);

  &.bs-light {
    color: #ff0000;
  }
}

.bs-vimeo:hover {
  background-color: #1ab7ea;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#1ab7ea, .9);

  &.bs-light {
    color: #1ab7ea;
  }
}

.bs-wechat:hover {
  background-color: #7bb32e;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#7bb32e, .9);

  &.bs-light {
    color: #7bb32e;
  }
}

.bs-discord:hover {
  background-color: #7289da;
  box-shadow: 0 .5rem 1.125rem -.5rem rgba(#7289da, .9);

  &.bs-light {
    color: #7289da;
  }
}

.bs-tiktok:hover {
  background-color: #25f4ee;
  box-shadow: 0 .5rem 1.125rem -.5rem #25f4ee;

  &.bs-light {
    color: #25f4ee;
  }
}

.bs-whatsapp:hover {
  background-color: #25d366;
  box-shadow: 0 .5rem 1.125rem -.5rem #25d366;

  &.bs-light {
    color: #25d366;
  }
}


// Share buttons

.btn-share {
  display: inline-block;
  padding: $btn-share-padding-y $btn-share-padding-x;
  transition: $btn-share-transition;
  @include border-radius($btn-share-border-radius);
  font-size: $btn-share-font-size;
  text-decoration: none !important;
  vertical-align: middle;

  > i {
    margin: {
      top: -.125rem;
      right: .3125rem;
    }
    font-size: 1.1em;
    vertical-align: middle;
  }

  // Twitter
  &.btn-twitter {
    background-color: rgba(#1da1f2, .08);
    color: #1da1f2;

    &:hover {
      background-color: #1da1f2;
    }
  }

  // Instagram
  &.btn-instagram {
    background-color: rgba(#5851db, .08);
    color: #5851db;

    &:hover {
      background-color: #5851db;
    }
  }

  // Facebook
  &.btn-facebook {
    background-color: rgba(#3b5998, .08);
    color: #3b5998;

    &:hover {
      background-color: #3b5998;
    }
  }

  &:hover {
    color: $white;
    box-shadow: none;
  }
}
