#customers-address-list {
  .address-list {
    margin-left: -20px;
    margin-right: -20px;
    border-top: 1px solid $color-grey4;

    .address-info {
      padding: 24px 32px;
      border-bottom: 1px solid $color-grey4;

      &.add-address {
        margin-left: 0;
        margin-right: 0;
        padding-top: 52px;
        padding-bottom: 52px;
      }

      h4 {
        margin-bottom: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }

      p {
        margin-bottom: 16px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .remove {
          color: $color-red;
        }
      }
    }
  }

  .add-address {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 52px;
    padding-bottom: 52px;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid $color-grey4;

    a {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-style: normal;
      line-height: 150%;
      color: $color-black;

      &:hover {
        .add-txt {
          text-decoration-line: none;
        }
      }

      .plus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: $color-black;
        font-size: 16px;
        color: $color-white;

        i {
          transform: translateY(1px);
        }
      }

      .add-title {
        margin-top: 18px;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
      }

      .add-txt {
        font-size: 14px;
        font-weight: 500;
        text-decoration-line: underline;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .address-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-left: 0;
      margin-right: 0;
      border-top: 0;

      .address-info {
        border: 1px solid $color-grey4;
      }
    }

    .add-address {
      margin-left: 0;
      margin-right: 0;
    }
  }
}