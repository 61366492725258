.news-element {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .img {
    display: block;
    line-height: 0;
    margin-bottom: 10px;

    img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 1.03/1;
    }
  }

  .date {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: $color-black;
  }

  .title {
    margin-top: 14px;
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color-black;

    a {
      color: $color-black;
    }
  }

  .link {
    margin-top: auto;
  }

  @include media-breakpoint-up(lg) {
    .img {
      margin-bottom: 16px;
    }

    .title {
      margin-top: 16px;
      margin-bottom: 18px;
      font-size: 16px;
    }

    .title {
      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}