#pages-detailed {
  padding-top: 25px;

  &.no-title {
    padding-top: 0;
  }

  .page-title {
    margin-bottom: 24px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  .pages-detailed-wrp {
    display: flex;

    .page-content {
      flex: 1 0 0;

      &.no-side {
        margin-left: auto;
        margin-right: auto;
      }

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .news-list-wrp {
    margin-bottom: 80px;
  }

  .news-list {
    display: grid;
    row-gap: 30px;
  }

  .news-detailed {
    margin-bottom: 80px;

    .date {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: $color-black;
      margin-bottom: 24px;
    }
  }

  @include media-breakpoint-up(sm) {
    .news-list {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 27px;
    }
  }
  @include media-breakpoint-up(lg) {
    .pages-detailed-wrp {
      display: flex;

      .page-title {
        font-size: 40px;
      }

      .page-content {
        padding-top: 42px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 45px;

    &.pages-detailed {
      padding-top: 0;
    }

    .news-list {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 40px;
    }

    .pages-detailed-wrp {
      display: flex;

      .page-content {
        max-width: 821px;
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .pages-detailed-wrp {
      display: flex;

      .page-content {
        max-width: 885px;
        padding-left: 64px;
        padding-right: 64px;
      }
    }
  }
}