#cart-info {
  .cart-info {
    position: relative;
    display: inline-block;
    margin-right: 0;
    padding-right: 0;
    font-size: 19px;
    line-height: 0;
    color: $color-black;

    &:after {
      left: calc(50% + 5px);
    }

    .total {
      position: absolute;
      top: 2px;
      right: -7px;
      z-index: 1;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: $color-black;
      color: $color-white;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
    }
  }

  #cart-info-details {
    position: fixed;
    top: 64px;
    left: 0;
    z-index: $zindex-modal-backdrop;
    width: 100%;
    height: calc(100% - 64px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &.active {
      opacity: 1;
      visibility: visible;

      .overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba($color-black, 0.50);
      opacity: 0;
      visibility: hidden;
    }

    .cart-info-details {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 1px solid $color-grey4;
      background: $color-white;
    }

    .cart-info-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 20px 18px;
      border-bottom: 1px solid $color-grey4;

      h2 {
        margin-bottom: 0;
        margin-right: 10px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;

        i {
          margin-right: 8px;
        }
      }

      .close-btn {
        position: static;
        padding-right: 0;
        flex-shrink: 0;
      }
    }

    #cart-detailed {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      overflow-y: auto;

      .container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
      }

      .cart-col {
        width: 100%;
      }

      #cart-summary {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .cart-content {
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          background-color: #F0F1F3;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: $color-black;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0;
          background-color: #F0F1F3;
        }
      }

      .cart-actions {
        padding-left: 20px;
        padding-right: 20px;
        border-top: 1px solid $color-grey4;
      }

      #cart-items {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-top: 0;
      }
    }

    .btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 8px;
      width: 100%;
      padding-bottom: 24px;

      .button {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    #cart-info-details {
      display: flex;
      justify-content: flex-end;

      &.active {
        .cart-info-details {
          transform: translateX(0);
        }
      }

      .cart-info-details {
        width: 360px;
        transform: translateX(30px);
        transition: all 0.3s ease;

        .cart-info-header {
          padding: 24px 24px 18px;

          h2 {
            font-size: 24px;
          }
        }

        .btns {
          padding-bottom: 32px;
        }

        #cart-detailed {
          #cart-vouchers {
            padding: 24px;
            margin-left: -24px;
            margin-right: -24px;
          }

          #cart-summary {
            margin-top: 28px;
            margin-bottom: 28px;
          }

          .cart-content {
            padding-left: 24px;
            padding-right: 24px;
          }

          .cart-actions {
            padding-left: 24px;
            padding-right: 24px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .cart-info {
      .total {
        right: -5px;
      }
    }
  }
}