#customers-manage-addresses {
  #customers-add-address {
    padding-top: 32px;

    .back {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 12px;

      i {
        margin-left: 0;
        margin-right: 8px;
        font-size: 10px;
      }
    }

    h2 {
      margin-bottom: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }

    .button {
      min-width: 140px;
      margin-top: 18px;
    }
  }
}