.customers-cart-login {
  padding-top: 16px;
  padding-bottom: 80px;

  .quest-login {
    margin-top: 24px;
    padding-top: 32px;
    border-top: 1px solid $color-grey4;

    h1 {
      margin-bottom: 10px !important;
    }

    .quest-desc {
      margin-bottom: 16px;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 0;
    padding-bottom: 0;

    .image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      .content-inner {
        max-width: 434px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
        padding-bottom: 80px;
      }
    }
  }
}