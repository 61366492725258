.customers-login {
  @include media-breakpoint-up(lg) {
    display: flex;

    .content {
      order: 1;
      width: 56%;
      padding: 40px 64px;
    }

    .image {
      order: 2;
      width: 44%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}