#orders-listing {
  .order-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $color-grey4;

    &:last-child {
      margin-bottom: 0;
    }

    .img {
      display: block;
      width: 72px;
      height: 72px;
      flex-shrink: 0;
      margin-right: 16px;
      border: 1px solid $color-grey4;
      background: $color-white;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .order-item-content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
  }

  .price-col {
    padding-left: 10px;
  }

  .status {
    margin-bottom: 8px;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  .number {
    display: block;
    margin-bottom: 10px;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }

  .date {
    display: block;
    color: $color-grey7;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }

  .sum {
    display: block;
    margin-bottom: 8px;
    color: $color-black;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
  }

  .total {
    display: block;
    color: $color-black;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  @include media-breakpoint-up(xs) {
    .price-col {
      padding-left: 16px;
    }

    .number {
      font-size: 14px;
    }

    .date {
      font-size: 14px;
    }

    .sum {
      font-size: 14px;
    }
  }
  @include media-breakpoint-up(lg) {
    .order-item {
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      .img {
        width: 124px;
        height: 124px;
      }

      .status {
        margin-bottom: 16px;
      }

      .number {
        font-size: 16px;
      }

      .sum {
        font-size: 16px;
      }
    }
  }
}